export const getLogs = async (page, errorCallback, successCallback) => {
    let logs = await fetch(`${window.API_ENDPOINT}/log${page ? `?page=${page}` : ''}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            return res.data.logs;
        }
    })
    .catch(err => {
        console.log(err);
    });

    return logs;
}

export const getLogsMonth = async (month, year) => {
    let logs = await fetch(`${window.API_ENDPOINT}/log/month/${month}/${year}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            return res.data.logs;
        }
    })
    .catch(err => {
        console.log(err);
    });

    return logs;
}

export const getLogsBetween = async (startDate, endDate, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/log/between?startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            successCallback(res.data.logs);
        }
    })
    .catch(err => {
        console.log(err);
        errorCallback(err);
    });
}

export const saveNewLog = async (data, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/log`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
            let { status, message } = res;

            if(status === 200) {
                successCallback(res);
            } else if (status === 500) {
                errorCallback("server", message);
            } else {
                errorCallback("authorization", message);
            }
        })
        .catch(err => {
            errorCallback("server", err);
        });
}