import React from 'react';
import { Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Contexts
import { StripeProvider } from 'react-stripe-elements';
import { LogProvider } from '../../Contexts/LogContext/LogContext';
import { CheckInProvider } from '../../Contexts/CheckInContext/CheckInContext';
import { ProfileProvider } from '../../Contexts/ProfileContext/ProfileContext';
import { SubscriptionProvider } from '../../Contexts/SubscriptionContext/SubscriptionContext';
import { ProgressProvider } from '../../Contexts/ProgressContext/ProgressContext';

// Containers
import Initialised from '../Initialised';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Main from '../Main/Main';
import Dashboard from '../Dashboard/Dashboard';
import Workout from '../Workout/Workout';
import Statistics from '../Statistics/Statistics';
import Profile from '../Profile/Profile';
import WorkoutCreate from '../WorkoutCreate/WorkoutCreate';
import WorkoutEdit from '../WorkoutEdit/WorkoutEdit';
import Progress from '../Progress/Progress';
import Nutrition from '../Nutrition/Nutrition';

// Components
import Loader from '../../Components/Loader/Loader';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Log from '../../Components/Log/Log';

// Utilities
import { ProtectedPage } from '../../Utils/PageProtection/PageProtection';
import LogBookEntry from '../../Components/LogBook/LogBookEntry';
import { NutritionProvider } from '../../Contexts/NutritionContext/NutritionContext';


const MainContainer = (props) => {
    return (
        <StripeProvider apiKey="pk_test_wZLYPSrYJlnUlWNAqw1eCc4p">
              <ProfileProvider>
                <CheckInProvider>
                  <NutritionProvider>
                    <SubscriptionProvider>
                      <Header />
                      <Sidebar />
                      <Main>
                        <Initialised>
                          <LogProvider>
                            <Route path="/" component={Dashboard} exact />
                            <Route path="/workout" component={Workout} />
                            <Route path="/log/:workoutid" component={Log} exact />
                            <Route path="/log/entry/:logid" component={LogBookEntry} exact />
                          </LogProvider>
                          <Route path="/statistics" component={Statistics} />
                          <Route path="/profile" component={Profile} exact />
                          <Route path="/workout/create" component={WorkoutCreate} exact />
                          <Route path="/workout/edit/:workoutid" component={WorkoutEdit} exact />
                          <Route path="/nutrition" component={Nutrition} />
                          <ProgressProvider>
                            <Route path="/progress" component={Progress} exact />
                          </ProgressProvider>
                        </Initialised>
                      </Main>
                      <ToastContainer />
                      <Loader />
                    </SubscriptionProvider>
                  </NutritionProvider>
                </CheckInProvider>
              </ProfileProvider>
            </StripeProvider>
    );
}

export default MainContainer;