import React from 'react';

const VideoLibraryPagination = ({ page, pageCount, setPage }) => {
    return (
        <div className="pagination">
            <div className="pagination__prev">
                <button className="icon-btn icon-btn--white" 
                        disabled={page === 1 ? true : false}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            setPage(page - 1);
                            }}>
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#chevron-left" />
                    </svg>
                </button>
            </div>
            <div className="pagination__next">
                <button className="icon-btn icon-btn--white" 
                        disabled={page === pageCount ? true : false}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            setPage(page + 1);
                            }}>
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#chevron-right" />
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default VideoLibraryPagination;