import React from 'react';

// Components
import ProgressGallery from '../../Components/ProgressGallery/ProgressGallery';

const Progress = (props) => {
    return (
        <div className="layout-grid">
            <ProgressGallery />
        </div>
    );
}

export default Progress;