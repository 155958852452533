import React from 'react';

// Components
import WorkoutEditor from '../../Components/WorkoutEditor/WorkoutEditor';

const WorkoutCreate = (props) => {
    return (
        <>
            <WorkoutEditor />
        </>
    );
}

export default WorkoutCreate;