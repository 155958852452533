import React, { useContext } from 'react';

// Contexts
import { SubscriptionContext } from '../../Contexts/SubscriptionContext/SubscriptionContext';

const PaymentHistory = (props) => {
    const subscriptionContext = useContext(SubscriptionContext);

    return (
        <div className="card payment-history">
                <div className="payment-history__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition"></use>
                        </svg>
                    </div>
                    <div className="payment-history__title">Billing</div>
                </div>
                <div className="table">
                    {subscriptionContext.state.transactions.length > 0 ?
                        <>
                        {subscriptionContext.state.transactions.map((transaction, index) => 
                        <div className="table-row" key={index}>
                            {transaction.date}
                            <span className="ml-auto">£{transaction.amount/100}</span>
                        </div>
                        )}
                        </>
                    :
                        <p>No transactions.</p>
                    }
                </div>
            </div>
    );
}

export default PaymentHistory;