import React from 'react';
import { Link } from 'react-router-dom';

const LogComplete = ({ resetLog }) => {
    return (
        <div className="log-complete">
            <div className="log-complete__inner">
                <img src="/images/complete-illustration.svg" alt="Log Complete" />
                <h3>Workout Complete!</h3>
                <Link to="/workout" className="btn btn--icon" onClick={() => resetLog()}>
                    Back to My Workout
                    <span>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#dumbbell" />
                        </svg>
                    </span>    
                </Link>
            </div>
        </div>
    )
}

export default LogComplete;