import React, { useState, useEffect } from 'react';

// Utils
import { exerciseDataById } from '../../Utils/Exercise/Exercise';

// Components
import WorkoutEditorSetResistance from './WorkoutEditorSetResistance';
import WorkoutEditorSetCardio from './WorkoutEditorSetCardio';

const WorkoutEditorExercise = ({ index, exercise, exercises, day, removeExercise, addSet, removeSet, addSuperset, handleSetDataChange }) => {
    const [exerciseData, setExerciseData] = useState(false);

    useEffect(() => {
        let data = exerciseDataById(exercises, exercise.exerciseId);
        setExerciseData(data);
    }, []);

    return (
        <>
        {exerciseData && 
            <div className="workout-editor-exercise">
                {exercise.superset &&
                    <div className="workout-editor-exercise__tag">
                        {exercise.additionalExercises.length > 1 ? 'Giant Set' : 'Superset'}
                    </div>
                }
                <div className="workout-editor-exercise__header">
                    <div className="thumbnail mr-1">
                        <img src={exerciseData.image} alt={exerciseData.name} />
                    </div>
                    <span>{exerciseData.name}</span>
                    <div className="ml-auto">
                        <button className="sidebar-item" onClick={() => addSet(day.id, exercise.id, exerciseData._id, exercise.superset)}>
                            <div className="sidebar-item__icon">
                                <svg>
                                    <use xlinkHref="/images/icon-library.svg#plus" />
                                </svg>
                            </div>
                            <span className="sidebar-item__text">Add Set</span>
                        </button>
                        <button className="sidebar-item" onClick={() => addSuperset(day.id, exercise.id)}>
                            <div className="sidebar-item__icon">
                                <svg>
                                    <use xlinkHref="/images/icon-library.svg#plus" />
                                </svg>
                            </div>
                            <span className="sidebar-item__text">Add Superset</span>
                        </button>
                        <button className="sidebar-item" onClick={() => removeExercise(day.id, exercise.id, false, exercise.id)}>
                            <div className="sidebar-item__icon">
                                <svg>
                                    <use xlinkHref="/images/icon-library.svg#close" />
                                </svg>
                            </div>
                            <span className="sidebar-item__text">Remove Exercise</span>
                        </button>
                    </div>
                </div>
                <div className="workout-editor-exercise__main">
                    {exercise.sets.map((set, index) => {
                        if(exerciseData.type === "resistance") {
                            return <WorkoutEditorSetResistance 
                                key={index} 
                                index={index} 
                                set={set}
                                setKey="a"
                                day={day}
                                exercise={exercise}
                                removeSet={(dayid, exerciseid, setid) => removeSet(dayid, exerciseid, setid)}
                                handleChange={(dayid, exerciseid, setid, e, supersetitem, parentId) => handleSetDataChange(dayid, exerciseid, setid, e, supersetitem, parentId)} />
                        } else {
                            return <WorkoutEditorSetCardio 
                                key={index} 
                                index={index} 
                                set={set}
                                setKey="a"
                                day={day}
                                exercise={exercise}
                                removeSet={(dayid, exerciseid, setid) => removeSet(dayid, exerciseid, setid)}
                                handleChange={(dayid, exerciseid, setid, e, supersetitem, parentId) => handleSetDataChange(dayid, exerciseid, setid, e, supersetitem, parentId)} />

                        }
                    })}
                </div>
                {exercise.superset &&
                    <>
                    {exercise.additionalExercises.map((ssexercise, index) => {
                        let exerciseData = exerciseDataById(exercises, ssexercise.exerciseId);
                        const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
                        let setKey = alphabet[index + 1];
                        return <>
                                <div key={index} className="workout-editor-exercise__header">
                                    <div className="thumbnail mr-1">
                                        <img src={exerciseData.image} alt={exerciseData.name} />
                                    </div>
                                    <span>{exerciseData.name}</span>
                                    <div className="ml-auto">
                                        <button className="sidebar-item" onClick={() => removeExercise(day.id, ssexercise.id, true, exercise.id)}>
                                            <div className="sidebar-item__icon">
                                                <svg>
                                                    <use xlinkHref="/images/icon-library.svg#close" />
                                                </svg>
                                            </div>
                                            <span className="sidebar-item__text">Remove Exercise</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="workout-editor-exercise__main">
                                    {ssexercise.sets.map((ssset, index) => {
                                        if(exerciseData.type === "resistance") {
                                            return <WorkoutEditorSetResistance 
                                                key={index}
                                                index={index}
                                                set={ssset}
                                                setKey={setKey}
                                                day={day}
                                                exercise={ssexercise}
                                                parentId={exercise.id}
                                                handleChange={(dayid, exerciseid, setid, e, supersetitem, parentId) => handleSetDataChange(dayid, exerciseid, setid, e, supersetitem, parentId)} />
                                        } else {
                                            return <WorkoutEditorSetCardio
                                                key={index}
                                                index={index}
                                                set={ssset}
                                                setKey={setKey}
                                                day={day}
                                                exercise={ssexercise}
                                                parentId={exercise.id}
                                                handleChange={(dayid, exerciseid, setid, e, supersetitem, parentId) => handleSetDataChange(dayid, exerciseid, setid, e, supersetitem, parentId)} />

                                        }
                                    })}
                                </div>
                                </>
                    }
                    )}
                    </>
                }
            </div>
        }
        </>
    );
}

export default WorkoutEditorExercise;