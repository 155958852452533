import React, { useContext } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { ProfileContext } from '../../Contexts/ProfileContext/ProfileContext';

// Components
import DetailsForm from '../../Components/Forms/DetailsForm/DetailsForm';

const UserDetailsCard = (props) => {
    const stateStoreContext = useContext(StateStoreContext);
    const profileContext = useContext(ProfileContext);

    return (
        <div className="card personal-details-card">
                <div className="personal-details-card__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition"></use>
                        </svg>
                    </div>
                    <div className="personal-details-card__title">My Details</div>
                </div>
                <div className="personal-details-card__content">
                    <DetailsForm details={stateStoreContext.state.user} handleFormSubmit={(details) => profileContext.updateUser(details)} />
                </div>
            </div>
    );
}

export default UserDetailsCard;