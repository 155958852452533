import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { ProfileContext } from '../../Contexts/ProfileContext/ProfileContext';

// Components
import Avatar from '../Avatar/Avatar';
import IconDropdown from '../IconDropdown/IconDropdown';

const ProfileCard = ({ hideDropdown = false }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const profileContext = useContext(ProfileContext);

    return (
        <div className="card profile-card">
            {Object.entries(stateStoreContext.state.user).length !== 0 && stateStoreContext.state.user.constructor === Object &&
                <>
                    <div className="profile-card__content">
                        <Avatar size="large" additionalClasses="mb-2" user={stateStoreContext.state.user} upload={true} changeAvatar={(formData) => profileContext.changeProfileAvatar(formData)} />
                        <p><b>{stateStoreContext.state.user.firstname} {stateStoreContext.state.user.lastname}</b></p>
                    </div> 
                    {!hideDropdown &&
                    <IconDropdown icon="options" location="right">
                        <Link to="profile">Profile</Link>
                    </IconDropdown>
                    }
                </>
            }
        </div>
    );
}

export default ProfileCard;