import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

// Contexts
import { WelcomeContext } from '../../Contexts/WelcomeContext/WelcomeContext';
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import WorkoutOverview from '../../Components/WorkoutOverview/WorkoutOverview';

const WelcomeComplete = ({ history }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const welcomeContext = useContext(WelcomeContext);
    const [animate, setAnimate] = useState(false);

    const dob = moment(stateStoreContext.state.user.dob, 'DD/MM/YYYY');
    const age = (moment().diff(dob, 'years', true)).toFixed(0);
    
    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 500);
    }, []);

    const goBack = () => {
        welcomeContext.setGenerated(false);
        welcomeContext.setPlan({});
    }

    return (
        <div className={`general-content ${animate ? 'animate-in' : ''} ${welcomeContext.state.welcomeComplete ? 'animate-out' : ''}`}>
            <div className="general-content__inner">
                <h1 className="mb-3">Your Personal Fitness Plan</h1>
                <div className="f-container mb-3">
                    <div className="f-item">
                        <div className="f-container">
                            <div className="f-item">
                                <div className="key-value">
                                    <p><b>Name</b></p>
                                    <p>{stateStoreContext.state.user.firstname + " " + stateStoreContext.state.user.lastname}</p>
                                </div>
                                <div className="key-value">
                                    <p><b>Gender</b></p>
                                    <p>{welcomeContext.state.welcomeData.gender}</p>
                                </div>
                                <div className="key-value">
                                    <p><b>Age</b></p>
                                    <p>{age}</p>
                                </div>
                            </div>
                            <div className="f-item">
                                <div className="key-value">
                                    <p><b>Height</b></p>
                                    <p>{welcomeContext.state.welcomeData.height}cm</p>
                                </div>
                                <div className="key-value">
                                    <p><b>Weight</b></p>
                                    <p>{welcomeContext.state.welcomeData.weight}kg</p>
                                </div>
                                <div className="key-value">
                                    <p><b>Something</b></p>
                                    <p>Else here</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="stat-card-container">
                        <div className="stat-card">
                            <div className="stat-card__icon">
                                <svg>
                                    <use xlinkHref="/images/icon-library.svg#calendar" />
                                </svg>
                            </div>
                            <div className="stat-card__info">
                                <p className="stat-card__label"><b>Goal</b></p>
                                <p className="stat-card__figure"><b>{welcomeContext.state.welcomeData.goal}</b></p>
                            </div>
                        </div>
                        <div className="stat-card">
                            <div className="stat-card__icon">
                                <svg>
                                    <use xlinkHref="/images/icon-library.svg#calendar" />
                                </svg>
                            </div>
                            <div className="stat-card__info">
                                <p className="stat-card__label"><b>Start Date</b></p>
                                <p className="stat-card__figure"><b>{moment(welcomeContext.state.welcomeData.startDate, 'YYYY-MM-DD').format('do MMM YYYY')}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <h2>Nutrition</h2>
                <hr />
                <div className="f-container">
                    <div className="f-item">
                        <p>Calories</p>
                        <p>{welcomeContext.state.plan.nutrition.target}kcals</p>
                    </div>
                    <div className="f-item">
                        <p>Protein</p>
                        <p>{welcomeContext.state.plan.nutrition.protein}g</p>
                    </div>
                    <div className="f-item">
                        <p>Carbohydrates</p>
                        <p>{welcomeContext.state.plan.nutrition.carbohydrates}g</p>
                    </div>
                    <div className="f-item">
                        <p>Fat</p>
                        <p>{welcomeContext.state.plan.nutrition.fats}g</p>
                    </div>
                </div>
                <hr className="mb-3" />
                <h2>Workout Plan</h2>
                <hr />
                <WorkoutOverview plan={welcomeContext.state.plan.workout.data} welcome={true} />
                <hr />
                <div className="f-container">
                    <button className="icon-btn" onClick={() => goBack()}>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-left" />
                        </svg>
                    </button>
                    <button className="btn ml-auto" onClick={() => welcomeContext.getStarted()}>Get Started</button>
                </div>
            </div>
        </div>
    );
}

export default WelcomeComplete;