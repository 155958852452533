import React, { useState } from 'react';

// Utils
import { firstChar } from '../../Utils/Manipulation/Manipulation';

const Avatar = ({size = "", additionalClasses = "", user, upload = false, changeAvatar}) => {
    const [loading, setLoading] = useState(true);
    
    const handleFileUpload = (e) => {
        let formData = new FormData();

        formData.append('avatar', e.target.files[0]);
        changeAvatar(formData);
    }

    return (
        <>
        {user &&
        <div className={`avatar avatar--${size} ${additionalClasses}`}>
            <span className="avatar__initials">
                {user.firstname && user.lastname ?
                    <>
                    {firstChar(user.firstname)}{firstChar(user.lastname)}
                    </>
                    :
                    <></>
                }
            </span>
            {user.avatar &&
                <img className="avatar__image" src={`${window.API_ENDPOINT}/media/avatar/${user.avatar}`} onLoad={() => setLoading(false)} />
            }
            {user.avatar && loading &&
                <div className="image-loader"></div>
            }
            {upload &&
                <React.Fragment>
                    <div className="avatar__overlay">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#upload" />
                        </svg>
                    </div>
                    <input type="file" name="avatar" onChange={(e) => handleFileUpload(e)} />
                </React.Fragment>
            }
        </div>}
        </>
    );
}

export default Avatar;