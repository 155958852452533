import * as Yup from 'yup';

export const welcomeObjSchema = Yup.object().shape({
    gender: Yup.string()
                .required('Gender is required.'),
    units: Yup.string()
                .required('Units is required.'),
    height: Yup.string()
                .required('Height is required.')
                .min(0),
    weight: Yup.string()
                .required('Weight is required.')
                .min(0),
    activity: Yup.string()
                .required('Activity level is required.'),
    goal: Yup.string()
                .required('Goal is required.'),
    experience: Yup.string()
                .required('Experience is required.'),
    workoutDays: Yup.string()
                .required('Workout days is required.'),
    startDate: Yup.date()
                .required('Start date is required.'),
    missionStatement: Yup.string()
});