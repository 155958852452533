import React, { createContext, useState, useEffect, useContext } from 'react';

// Contexts
import { GlobalUIContext } from '../GlobalUIContext/GlobalUIContext';
import { StateStoreContext } from '../StateStoreContext/StateStoreContext';

// Utils
import { saveNewLog } from '../../Utils/Requests/Log';

const LogContext = createContext();

const LogProvider = ({ children }) => {
    const globalUIContext = useContext(GlobalUIContext);
    const stateStoreContext = useContext(StateStoreContext);

    const [workout, setWorkout] = useState({});
    const [log, setLog] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [logBookPage, setLogBookPage] = useState(1);
    const [exerciseCount, setExerciseCount] = useState(0);
    const [currentExercise, setCurrentExercise] = useState(0);
    const [complete, setComplete] = useState(false);
    const [logGhost, setLogGhost] = useState({});

    useEffect(() => {
        if(Object.entries(workout).length !== 0 && workout.constructor === Object) {
            let count = workout.exercises.length;

            setExerciseCount(count);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workout]);

    const resetLog = async () => {
        setWorkout({});
        setLog({});
        setLogBookPage(1);
        setExerciseCount(0);
        setCurrentExercise(0);
        setComplete(false);
        setLogGhost({});
    }

    useEffect(() => {
        stateStoreContext.paginatedLogs(logBookPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logBookPage]);

    const getLogGhost = (dayId) => {
        let l = stateStoreContext.state.logs.length,
            exists = false;

        if(l > 0) {
            // l = l - 1;
            for(let i = 0; i < l; i++) {
                if(stateStoreContext.state.logs[i].data.id === dayId) {
                    exists = true;
                    setLogGhost(stateStoreContext.state.logs[i].data);
                    i = l + 1;
                }
            }
        }

        if(!exists) {
            let initialLogGhost = stateStoreContext.state.plan.workout.workoutuser.data.filter(day => day.id === dayId)[0];
            setLogGhost(initialLogGhost);
        }
    }

    const prevExercise = () => {
        setCurrentExercise(currentExercise - 1);
        window.scrollTo(0,0);
    }

    const nextExercise = () => {
        setCurrentExercise(currentExercise + 1);
        window.scrollTo(0,0);
    }

    const updateSet = (exerciseid, setid, data) => {
        let logObj = {...log};

        logObj.exercises.forEach(exercise => {
            if(exercise.id === exerciseid) {
                exercise.sets.forEach(set => {
                    if(set.id === setid) {
                        set[data.target.name] = data.target.value
                    }
                });
            }
        });

        setLog(logObj);
    }

    const updateSuperSet = (exerciseid, supersetid, setid, data) => {
        let logObj = {...log};

        logObj.exercises.forEach(exercise => {
            if(exercise.id === exerciseid) {
                exercise.additionalExercises.forEach(additional => {
                    if(additional.id === supersetid) {
                        additional.sets.forEach(set => {
                            if(set.id === setid) {
                                set[data.target.name] = data.target.value
                            }
                        });
                    }
                });
            }
        });

        setLog(logObj);
    }

    const updateNotes = (exerciseid, data) => {
        let logObj = {...log};

        logObj.exercises.forEach(exercise => {
            if(exercise.id === exerciseid) {
                exercise.notes = data.notes;
            }
        });

        setLog(logObj);
    }

    const completeWorkout = async (duration) => {
        globalUIContext.setLoading(true);

        const handleCompletetionError = (type, message) => {
            alert(type, message);
        }

        const handleCompletetionSuccess = (res) => {
            setTimeout(async () => {
                stateStoreContext.setLogs([res.data.savedLog, ...stateStoreContext.state.logs]);
                setComplete(true);
                globalUIContext.setLoading(false);
            }, 2000);
        }

        let data = {planid: stateStoreContext.state.plan._id, data: log, duration};

        await saveNewLog(data, handleCompletetionError, handleCompletetionSuccess);
    }

    return (
        <LogContext.Provider value={{
            state: {
                workout,
                log,
                selectedDate,
                logBookPage,
                exerciseCount,
                currentExercise,
                complete,
                logGhost
            },
            setWorkout,
            setLog,
            setSelectedDate,
            setLogBookPage,
            resetLog,
            prevExercise,
            nextExercise,
            updateSet,
            updateSuperSet,
            updateNotes,
            completeWorkout,
            getLogGhost
            }}>
            {children}
        </LogContext.Provider>
    );
}

const LogConsumer = LogContext.Consumer;

export { LogContext, LogProvider, LogConsumer }
