import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const LogBookItem = ({ log }) => {
    return (
        <div className="log-book-item">
            <div className="log-book-item__date">
                <svg>
                    <use xlinkHref="/images/icon-library.svg#calendar" />
                </svg>
                <p><b>{moment(log.date).format("Do MMM YYYY")}</b></p>
            </div>
            <div className="log-book-item__name">
                <p>{log.data.name}</p>
                <div className="log-book-item__stats">
                    <span>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#dumbbell" />
                        </svg>
                        8
                    </span>
                    <span className="yellow">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#trophy" />
                        </svg>
                        2
                    </span>
                </div>
            </div>
            <div className="log-book-item__action">
                <Link to={`/log/entry/${log._id}`} className="icon-btn">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#chevron-right" />
                    </svg>
                </Link>
            </div>
        </div>
    );
}

export default LogBookItem;