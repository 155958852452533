import React from 'react';
import { Route, NavLink } from 'react-router-dom';

// Components
import BodyStats from '../../Components/BodyStats/BodyStats';
import WorkoutStats from '../../Components/WorkoutStats/WorkoutStats';

const Statistics = (props) => {
    return (
        <>
            <div className="sub-nav">
                <NavLink to="/statistics" className="pill-btn" activeClassName="is-active" exact>Body Stats</NavLink>
                <NavLink to="/statistics/workout" className="pill-btn" activeClassName="is-active" exact>Workout Stats</NavLink>
            </div>
            <Route path="/statistics" component={BodyStats} exact />
            <Route path="/statistics/workout" component={WorkoutStats} exact />
        </>
    );
}

export default Statistics;