import React from 'react';

const LatestUpdates = (props) => {
    return (
        <>
        <h3 className="mt-2">Latest Updates</h3>
        <div className="latest-updates-container">
            <div className="item-card">
                <div className="item-card__image">
                    <img src="https://unsplash.it/600/600" />
                </div>
                <div className="item-card__content">
                    <div className="item-card__title">Chicken Curry Recipe</div>
                </div>
            </div>
            <div className="item-card">
                <div className="item-card__image">
                    <img src="https://unsplash.it/600/600" />
                </div>
                <div className="item-card__content">
                    <div className="item-card__title">Chicken Curry Recipe</div>
                </div>
            </div>
            <div className="item-card">
                <div className="item-card__image">
                    <img src="https://unsplash.it/600/600" />
                </div>
                <div className="item-card__content">
                    <div className="item-card__title">Chicken Curry Recipe</div>
                </div>
            </div>
            <div className="item-card">
                <div className="item-card__image">
                    <img src="https://unsplash.it/600/600" />
                </div>
                <div className="item-card__content">
                    <div className="item-card__title">Chicken Curry Recipe</div>
                </div>
            </div>
            <div className="item-card">
                <div className="item-card__image">
                    <img src="https://unsplash.it/600/600" />
                </div>
                <div className="item-card__content">
                    <div className="item-card__title">Chicken Curry Recipe</div>
                </div>
            </div>
            <div className="item-card">
                <div className="item-card__image">
                    <img src="https://unsplash.it/600/600" />
                </div>
                <div className="item-card__content">
                    <div className="item-card__title">Chicken Curry Recipe</div>
                </div>
            </div>
        </div>
        </>
    );
}

export default LatestUpdates;