import React from 'react';

const LogHeader = ({ workout, count, current, timer }) => {
    return (
        <div className="log-header">
            <div className="log-header__name">
                {workout.name}
            </div>
            <div className="log-header__timer ml-auto">
                <svg>
                    <use xlinkHref="/images/icon-library.svg#stopwatch" />
                </svg>
                <p>{timer}</p>
            </div>
            <div className="log-header__progress">
                {current}/{count}
            </div>
        </div>
    );
}

export default LogHeader;