import React from 'react';

const WorkoutEditorSetResistance = ({ set, index, setKey, day, exercise, handleChange, removeSet, parentId = false }) => {
    return (
        <div className="workout-editor-set">
            <div className="workout-editor-set__title">Set {index + 1}{exercise.superset || parentId !== false ? setKey : ''}</div>
            <div className="workout-editor-set__reps">
                <select name="repRange" value={set.repRange} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)}>
                    <option value="false">Reps</option>
                    <option value="true">Rep Range</option>
                </select>
                {set.repRange ?
                    <>
                        <input type="number" name="from" value={set.from} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
                        -
                        <input type="number" name="to" value={set.to} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
                    </>
                :
                    <input type="number" name="reps" value={set.reps} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
                }
            </div>
            <div className="workout-editor-set__weight">
                <span>Weight</span>
                <input type="number" name="weight" value={set.weight} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
            </div>
            <div className="workout-editor-set__actions">
                {parentId === false &&
                <button className="icon-btn" onClick={() => removeSet(day.id, exercise.id, set.id)}>
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#close" />
                    </svg>
                </button>
                }
            </div>
        </div>
    );
}

export default WorkoutEditorSetResistance;