import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// Contexts
import { LogContext } from '../../Contexts/LogContext/LogContext';
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import LogHeader from './LogHeader';
import LogBody from './LogBody';
import LogControl from './LogControl';
import LogComplete from './LogComplete';

const Log = (props) => {
    const logContext = useContext(LogContext);
    const stateStoreContext = useContext(StateStoreContext);

    const [timer, setTimer] = useState("00:00");

    const { workoutid } = useParams();

    useEffect(() => {
        // 1. First we want to clone the workout object to logContext
        let workoutObj = {};

        stateStoreContext.state.plan.workout.workoutuser.data.forEach(day => {
            if(day.id === workoutid) {
                workoutObj = day;
            }
        });

        const strippedWorkoutObj = JSON.parse(JSON.stringify(workoutObj));

        strippedWorkoutObj.exercises.forEach(exercise => {
            exercise.notes = "";
            exercise.sets.forEach(set => {
                set.reps = "";
                set.weight = "";
            });

            if(exercise.superset) {
                exercise.additionalExercises.forEach(additional => {
                    additional.sets.forEach(set => {
                        set.reps = "";
                        set.weight = "";
                    });
                });
            }
        });

        logContext.setWorkout(workoutObj);
        logContext.setLog(strippedWorkoutObj);
        logContext.getLogGhost(workoutid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var startTime = Math.floor(new Date() / 1000); //Get the starting time (right now) in seconds

        function startTimeCounter() {
            var now = Math.floor(Date.now() / 1000); // get the time now
            var diff = (now - startTime); // diff in seconds between now and start
            var h = 0;
            var m = Math.floor(diff / 60); // get minutes value (quotient of diff)
            if(m >= 60) {
                h = Math.floor((m / 60));
                m = parseInt(m) - (parseInt(h) * 60);
            }
            var s = Math.floor(diff % 60); // get seconds value (remainder of diff)
            h = checkTime(h);
            m = checkTime(m); // add a leading zero if it's single digit
            s = checkTime(s); // add a leading zero if it's single digit
            setTimer(`${h}:${m}:${s}`);
            setTimeout(startTimeCounter, 500); // set a timeout to update the timer
        }

        function checkTime(i) {
            if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
            return i;
        }

        startTimeCounter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {Object.entries(logContext.state.workout).length === 0 && logContext.state.workout.constructor === Object ?
                ""
            :
                <>
                {!logContext.state.complete ?
                    <>
                        <LogHeader workout={logContext.state.workout} 
                                count={logContext.state.exerciseCount}
                                current={logContext.state.currentExercise + 1}
                                timer={timer} />
                        {stateStoreContext.state.exercises.length > 0 &&
                            <LogBody exercise={logContext.state.workout.exercises[logContext.state.currentExercise]}
                                    log={logContext.state.log.exercises[logContext.state.currentExercise]}
                                    logGhost={logContext.state.logGhost.exercises[logContext.state.currentExercise]} />
                        }
                        <LogControl count={logContext.state.exerciseCount}
                                    current={logContext.state.currentExercise + 1}
                                    prevExercise={logContext.prevExercise}
                                    nextExercise={logContext.nextExercise}
                                    completeWorkout={() => logContext.completeWorkout(timer)} />
                    </> 
                :
                    <>
                        <LogComplete resetLog={() => logContext.resetLog()} />
                    </>
                }
                </>
            }
        </>
    );
}

export default Log;