import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';

// Contexts
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import LoginForm from '../../Components/Forms/LoginForm/LoginForm';

// Utilities
import { authenticate } from '../../Utils/Requests/Authentication';

const Login = (props) => {
    const globalUIContext = useContext(GlobalUIContext);
    const stateStoreContext = useContext(StateStoreContext);

    const [error, setError] = useState("");

    useEffect(() => {
        if(stateStoreContext.state.isLoggedIn) {
            props.history.push("/dashboard");
        }
    }, [stateStoreContext.state.isLoggedIn, props.history]);

    const submitCredentials = (credentials) => {
        // 1. Set loading true
        globalUIContext.setLoading(true);

        // 2. Make the authenication request (credentials object, error handler callback)
        authenticate(credentials, handleAuthenticationError, handleAuthenticationSuccess);
    }

    const handleAuthenticationError = (type, error) => {
        if(type === "authorization") {
            setError(error);
        } else {
            toast.error(error);
        }
        globalUIContext.setLoading(false);
    }

    const handleAuthenticationSuccess = () => {
        stateStoreContext.setInitialised(false);
        stateStoreContext.setInitialAppData();
    }

    return (
        <div className="split-panel">
            <div className="split-panel__section">
                <div className="split-panel__inner">
                    <div className="brand-logo">
                        <img src="/images/logo-full-white.svg" alt="PT Sphere Brand Logo" />
                    </div>
                    <LoginForm title="Login" 
                               handleFormSubmit={(credentials) => submitCredentials(credentials)}
                               error={error} />
                </div>
            </div>
            <div className="split-panel__section hidden-mobile">
                <div className="cover-image">
                    <img src="/images/login-image.jpg" alt="People working out using battle ropes" />
                </div>
            </div>
        </div>
    );
}

export default Login;