import React from 'react';
import { Elements } from 'react-stripe-elements';
import PaymentMethodFormInput from './PaymentMethodFormInput';

const PaymentMethodForm = (props) => {
    return (
        <div className="payment-method-form">
            <Elements>
                <PaymentMethodFormInput />
            </Elements>
        </div>
    );
}

export default PaymentMethodForm;