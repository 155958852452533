import React from 'react';

const Main = ({ children }) => {
    return (
        <div className="main-content">
            <div className="main-content__inner">
                {children}
            </div>
        </div>
    );
}

export default Main;