import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email address is required.'),
    password: Yup.string()
        .required('No password provided.') 
        .min(8, 'Password is too short - should be 8 chars minimum.')
});

export const welcomeSchema = Yup.object().shape({
    gender: Yup.string()
            .required('Please select your gender.'),
    units: Yup.string()
            .required('Please select your preferred unit types (Metric/Imperial'),
    height: Yup.number()
            .required('Please provide us with your height.')
            .min(0),
    weight: Yup.number()
            .required('Please provide us with your weight.')
            .min(0),
    activity: Yup.string()
                .required('Please provide us with your activity level')
});

export const welcome2Schema = Yup.object().shape({
    experience: Yup.string()
                    .required('Please select your gym experience.'),
    workoutDays: Yup.string()
                    .required('Please let us know how many days per week you can workout.'),
    startDate: Yup.date()
                    .required('Please select an official date that you would like to start your plan.')
});

export const detailsSchema = Yup.object().shape({
        firstname: Yup.string()
                      .required('Please provide your first name.'),
        lastname: Yup.string()
                     .required('Please provide your last name.'),
        email: Yup.string()
                  .email('Please enter a valid email address.')
                  .required('Please provide your email address.'),
        phone: Yup.string()
                  .required('Please enter your phone number.'),
        dob: Yup.string()
                .required('Please enter your date of birth.')
});

export const changePasswordSchema = Yup.object().shape({
        password: Yup.string()
                .required('No password provided.') 
                .min(8, 'Password is too short - should be 8 chars minimum.'),
        confirmPassword: Yup.string()
                .required('No password provided.') 
                .min(8, 'Confirm Password is too short - should be 8 chars minimum.')
});