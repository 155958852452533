import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { LogContext } from '../../Contexts/LogContext/LogContext';

const NextWorkoutCard = (props) => {
    const stateStoreContext = useContext(StateStoreContext);
    const logContext = useContext(LogContext);

    const [dayData, setDayData] = useState({name: "----------", specificDay: "----------"}),
        [dayLabel, setDayLabel] = useState(""),    
        todaysDate = moment();

    const getDayLabel = (day) => {
        let label = day;

        if(day === todaysDate.format("dddd").toLowerCase()) {
            label = "Today";
        }

        if(day === todaysDate.add(1, 'days').format("dddd").toLowerCase()) {
            label = "Tomorrow";
        }

        setDayLabel(label);
    }

    useEffect(() => {
        let dayName = todaysDate.format("dddd").toLowerCase(),
            isToday = false,
            dayObj = {};

        // 1. Check the plan to find if their are any workout days that land today
        stateStoreContext.state.plan.workout.workoutuser.data.forEach(day => {
            if (day.specificDay === dayName) {
                // TODO: Once workout logging has been setup, check that
                // a log entry does not exist for todays date, if so
                // keep isToday as false so that step 2 logic takes place
                isToday = true;
                dayObj = day;
            }
        });

        // 2. If not today, check the next future closest date
        if(!isToday) {
            for (let i = 1; i < 8; i++) {
                dayName = todaysDate.add(1, 'days').format("dddd").toLowerCase();
                stateStoreContext.state.plan.workout.workoutuser.data.forEach(day => {
                    if(day.specificDay === dayName && Object.entries(dayObj).length === 0 && dayObj.constructor === Object) {
                        dayObj = day;
                    }
                });
            }
        }
        
        getDayLabel(dayObj.specificDay);
        setDayData(dayObj);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="card next-workout">
            <div className="next-workout__icon">
                <div className="sidebar-item__icon">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#dumbbell" />
                    </svg>
                </div>
            </div>
            <div className="next-workout__content">
                <p className="next-workout__subtitle">Your next workout</p>
                <p className="next-workout__title">{dayData.name}</p>
                <p className="next-workout__subtitle">{dayLabel}</p>  
                <Link to={`/log/${dayData.id}`} className="btn btn--white btn--icon" onClick={() => logContext.resetLog()}>
                    Start workout
                    <span>                                
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-right" />
                        </svg>
                    </span>
                </Link>
            </div>
        </div>
    );
}

export default NextWorkoutCard;