import React from 'react';

const FilterDropdownItem = ({ options, selected, onSelect, label, selectText }) => {
    return (
        <div className="filter-dropdown__item">
            <label>{label}</label>
            <select value={selected} onChange={(e) => onSelect(e)}>
                <option value="">{selectText}</option>
                {options.length > 0 && options.map(option => 
                    <option value={option}>{option}</option>    
                )}
            </select>
        </div>
    );
}

export default FilterDropdownItem;