import React from 'react';

const ProgressUpload = ({ onUpload }) => {

    const handleFileUpload = (e) => {
        let items = e.target.files.length;

        let formData = new FormData();
        
        for(let i = 0; i < items; i++) {
            formData.append('file', e.target.files[i]);
        }
        
        onUpload(formData);
    }

    return (
        <div className="progress-upload">
            <div className="progress-upload__area">
                <input type="file" name="progress" multiple onChange={(e) => handleFileUpload(e)} />
                <div className="progress-upload__inner">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#image" />
                    </svg>
                    <h3>Upload your progress photos <span>here</span></h3>
                </div>
            </div>
        </div>
    );
}

export default ProgressUpload;