import React, { useState } from 'react';

const ProgressGalleryThumbnail = ({ image, openModal }) => {
    const [loading, setLoading] = useState(true);
    
    return (
        <div className={`progress-gallery-thumbnail ${loading ? 'is-loading' : ''}`}>
            <img src={`${window.API_ENDPOINT}/media/progress/${image.thumbnail}`} alt={image.date} onLoad={() => setLoading(false)} onClick={() => openModal()} />
            {loading && 
                <div className="image-loader"></div>
            }
        </div>
    );
}

export default ProgressGalleryThumbnail;