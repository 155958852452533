export const getCheckIns = async (months, errorCallback, successCallback) => {
    let logs = await fetch(`${window.API_ENDPOINT}/checkin?months=${months}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            return res.data.checkIns;
        }
    })
    .catch(err => {
        console.log(err);
    });

    return logs;
}

export const saveCheckIn = async (data, goalType, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/checkin`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({...data, goalType})
    })
    .then(res => res.json())
    .then(res => {
        let { status, message } = res;

        if(status === 200) {
            successCallback(res);
        } else if (status === 500) {
            errorCallback("server", message);
        } else {
            errorCallback("authorization", message);
        }
    })
    .catch(err => {
        errorCallback("server", err);
    });
}