import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';

// Contexts
import { GlobalUIContext } from '../GlobalUIContext/GlobalUIContext';

// Utils
import { progressUpload, getProgressPictures } from '../../Utils/Requests/Media';
import { toast } from 'react-toastify';

const ProgressContext = createContext();

const ProgressProvider = ({ children }) => {
    const globalUIContext = useContext(GlobalUIContext);

    const [uniqueDates, setUniqueDates] = useState([]);
    const [progressPictures, setProgressPictures] = useState([]);
    const [initialised, setInitialised] = useState(false);
    
    const initialise = async () => {
        const errorCallback = (err) => {
            globalUIContext.setLoading(false);
            toast.error("There was a problem retrieving your progress photos. Please refresh the page.");
        }

        const successCallback = (res) => {
            globalUIContext.setLoading(false);
            let uDates = res.data.progressPictures.map(picture => moment(picture.date).format("YYYY-MM-DD 00:00:00"));
            uDates = [...new Set(uDates)];
            setProgressPictures(res.data.progressPictures);
            setUniqueDates(uDates);
            setInitialised(true);
        }

        if(!initialised) {
            let month = moment().format("M"),
                year = moment().format("YYYY");

            await getProgressPictures(month, year, errorCallback, successCallback);
        }
    }

    const getProgressPicturesByMonth = async (date) => {
        const errorCallback = (err) => {
            globalUIContext.setLoading(false);
            toast.error("There was a problem retrieving your progress photos. Please refresh the page.");
        }

        const successCallback = (res) => {
            globalUIContext.setLoading(false);
            let uDates = res.data.progressPictures.map(picture => moment(picture.date).format("YYYY-MM-DD 00:00:00"));
            uDates = [...new Set(uDates)];
            setProgressPictures(res.data.progressPictures);
            setUniqueDates(uDates);
        }

        let month = moment(date).format("M"),
            year = moment(date).format("YYYY");

        await getProgressPictures(month, year, errorCallback, successCallback);

    }

    const uploadProgressPictures = async (formData) => {
        const errorCallback = (type, err) => {
            globalUIContext.setLoading(false);
            console.log(err);
            if(type === "filesize") {
                toast.warn("File size limit reached. Your selected files are too big.");
            } else {
                toast.error("There was a problem uploading your progress photos. Please try again.");
            }
        }

        const successCallback = (res) => {
            toast.success("Your progress photos have been uploaded.");
            setTimeout(() => {
                getProgressPicturesByMonth(new Date());
                globalUIContext.setLoading(false);
            }, 5000);
        }

        globalUIContext.setLoading(true);

        await progressUpload(formData, errorCallback, successCallback);
    }

    return (
        <ProgressContext.Provider value={{
            state: {
                initialised,
                progressPictures,
                uniqueDates
            },
            initialise,
            uploadProgressPictures,
            getProgressPicturesByMonth
            }}>
            {children}
        </ProgressContext.Provider>
    );
}

const ProgressConsumer = ProgressContext.Consumer;

export { ProgressContext, ProgressProvider, ProgressConsumer }
