import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

// Contexts
import { ProgressContext } from '../../Contexts/ProgressContext/ProgressContext';

// Components
import ProgressGallerySection from './ProgressGallerySection';
import ProgressUpload from './ProgressUpload';
import GalleryModal from '../GalleryModal/GalleryModal';

const ProgressGallery = (props) => {
    const progressContext = useContext(ProgressContext);
    const [modalData, setModalData] = useState({ open: false, startIndex: 0, imageList: [] });
    const [selectedDate, setSelectedDate] = useState(new Date());
    
    useEffect(() => {
        if(!progressContext.state.initialised) {
            progressContext.initialise();
        }
    }, []);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        progressContext.getProgressPicturesByMonth(date);
    }

    return (
        <div className="progress-gallery">
            <ProgressUpload onUpload={(data) => progressContext.uploadProgressPictures(data)} />
            <>
            <hr />
            <div className="progress-gallery__title">
                <h3>Progress Gallery</h3>
                <div className="ml-auto">
                    <DatePicker
                        selected={selectedDate}
                        onChange={date => handleDateChange(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        withPortal
                        maxDate={new Date()}
                        customInput={<button className>{moment(selectedDate).format("MMMM")} {moment(selectedDate).format("YYYY")}</button>} />
                </div>
            </div>
            
            {progressContext.state.initialised && progressContext.state.uniqueDates.length > 0 ?
                <>
                    {progressContext.state.uniqueDates.map((date, index) => {
                        let data = progressContext.state.progressPictures.filter(picture => moment(picture.date).format("YYYY-MM-DD 00:00:00") === date);
                        return <ProgressGallerySection 
                                    key={index} 
                                    date={moment(date).format("Do MMM YYYY")} 
                                    data={data}
                                    openModal={(startIndex, imageList) => setModalData({ open: true, startIndex: startIndex, imageList: imageList })} />
                    })}
                </>
            :
            <p>No Progress photos for this month.</p>
            }
            </>
            <GalleryModal modalData={modalData} close={() => setModalData({ open: false, startIndex: false, imageList: []})} />
        </div>
    );
}

export default ProgressGallery;