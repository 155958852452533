import React from 'react';

const ExerciseSelectorItem = ({ exercise, selected, onSelect }) => {
    return (
        <div className={`exercise-selector__item ${selected ? 'is-selected' : ''}`} onClick={() => onSelect(exercise._id)}>
            <div className="thumbnail mr-1">
                <img src={exercise.image} alt={exercise.name} />
            </div>
            <span>{exercise.name}</span>
        </div>
    );
}

export default ExerciseSelectorItem;