import React from 'react';
import ProgressGalleryThumbnail from './ProgressGalleryThumbnail';

const ProgressGallerySection = ({ date, data, openModal }) => {
    return (
        <div className="progress-gallery-section">
            <div className="progress-gallery-section__title">
                <svg>
                    <use xlinkHref="/images/icon-library.svg#calendar" />
                </svg>
                {date}
            </div>
            <div className="progress-gallery-section__list">
                {data && data.length > 0 ?
                    <>
                        {data.map((image, index) => <ProgressGalleryThumbnail key={index} image={image} openModal={() => openModal(index, data)} />)}
                    </>
                :
                    <p>No progress pictures found.</p>
                }
            </div>
            <hr />
        </div>
    );
}

export default ProgressGallerySection;