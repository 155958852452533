import React from 'react';
import { NavLink } from 'react-router-dom';

const WorkoutSubNav = (props) => {
    return (
        <div className="sub-nav">
            <NavLink to="/workout" className="pill-btn" activeClassName="is-active" exact>My Workout</NavLink>
            <NavLink to="/workout/logbook" className="pill-btn" activeClassName="is-active" exact>Log Book</NavLink>
            <NavLink to="/workout/library" className="pill-btn" activeClassName="is-active" exact>Exercise Library</NavLink>
        </div>
    );
}

export default WorkoutSubNav;