import React from 'react';

const ActivityCalendarSpacer = (props) => {
    return (
        <div className="calendar__grid-item">
            <span></span>
        </div>
    );
}

export default ActivityCalendarSpacer;