import React, { useContext } from 'react';

// Contexts
import { SubscriptionContext } from '../../Contexts/SubscriptionContext/SubscriptionContext';
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

const SubscriptionActionCard = (props) => {
    const subscriptionContext = useContext(SubscriptionContext);
    const stateStoreContext = useContext(StateStoreContext);
    return (
        <div className="card payment-history">
                <div className="payment-history__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition"></use>
                        </svg>
                    </div>
                    <div className="payment-history__title">My Subscription</div>
                </div>
                <div className="payment-history__content">
                    <p>
                        <b>Status: </b>
                        {stateStoreContext.state.user.status === 2 && "Active"}
                        {stateStoreContext.state.user.status === 3 && "Cancelling - billing period end"}
                        {stateStoreContext.state.user.status === 4 && "Cancelled"}
                    </p>
                    {stateStoreContext.state.user.status < 3 ? 
                        <button className="btn" onClick={() => subscriptionContext.cancelSubscription()}>Cancel Account</button>
                        :
                        <button className="btn" onClick={() => subscriptionContext.resubscribe()}>Re-enable Account</button>
                    }
                </div>
            </div>
    );
}

export default SubscriptionActionCard;