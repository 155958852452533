import React, { useState, useEffect } from 'react';

const GalleryModal = ({ modalData : { open, startIndex, imageList }, close }) => {
    const [loading, setLoading] = useState(true);
    const [index, setIndex] = useState(false);

    useEffect(() => {
        setIndex(startIndex);
    }, [startIndex]);

    const onClose = (e) => {
        if(e.target === e.currentTarget) {
            setIndex(false);
            setLoading(true);
            close();
        }
    }

    return (
        <div className={`gallery-modal-overlay ${open ? 'is-open' : ''}`} onClick={(e) => onClose(e)}>
            {loading && <div className="image-loader"></div>}
            {imageList.length > 0 && index !== false && index !== undefined &&
                <div className="gallery-modal-content">
                    <img src={`${window.API_ENDPOINT}/media/progress/${imageList[index].image}`} alt="test" onLoad={() => setLoading(false)} />
                </div>
            }
            <div className="gallery-modal-controls">
                <button 
                    onClick={() => { setLoading(true); setIndex(index - 1); }} 
                    disabled={index === 0}>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-left" />
                        </svg>
                </button>
                <button 
                    onClick={() => { setLoading(true); setIndex(index + 1); }} 
                    disabled={index === (imageList.length - 1)}>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-right" />
                        </svg>
                </button>
            </div>
        </div>
    );
}

export default GalleryModal;