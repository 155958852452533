import React from 'react';

const BlockedContainer = (props) => {
    return (
        <>
            Account Blocked
        </>
    );
}

export default BlockedContainer;