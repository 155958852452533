import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

// Utilities
import { changePasswordSchema } from '../../../Utils/Validation/FormValidation';

const ChangePasswordForm = ({ handleFormSubmit, error }) => {
    const [confirm, setConfirm] = useState("");

    const handleSubmission = (values) => {
        if(values.password !== values.confirmPassword) {
            setConfirm("Passwords do not match. Please try again.");
        } else {
            setConfirm("");
            handleFormSubmit(values);
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: ''
                }}
                validationSchema={changePasswordSchema}
                onSubmit={(credentials) => {
                    handleSubmission(credentials);
                }}>
                {({ errors, touched }) => (
                    <>
                        <Form>
                            <div className="form-group">
                                <label htmlFor="password">New Password</label>
                                <Field type="password" name="password" id="password" />
                                {errors.password && touched.password ? <div className="error">{errors.password}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirm New Password</label>
                                <Field type="password" name="confirmPassword" id="confirmPassword" />
                                {errors.confirmPassword && touched.confirmPassword ? <div className="error">{errors.confirmPassword}</div> : null}
                            </div>
                            <button className="btn btn--white btn--icon" type="submit">
                                Change Password
                                <span>
                                    <svg>
                                        <use xlinkHref="/images/icon-library.svg#chevron-right" />   
                                    </svg> 
                                </span>    
                            </button>
                            {error !== "" && <p>{error}</p>}
                            {confirm !== "" && <p>{confirm}</p>}
                        </Form>
                    </>
                )}
                </Formik>
        </React.Fragment>
    );
}

export default ChangePasswordForm;