import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

// Contexts
import { NutritionContext } from '../../Contexts/NutritionContext/NutritionContext';
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';

// Components
import ProactiveNutritionDrawer from './ProactiveNutrtionDrawer';
import ProactiveNutritionConfirmation from './ProactiveNutritionConfirmation';

// Utils
import { calcOTAdjustments, confirmOTAdjustments, fetchUpcomingOTAdjustments } from '../../Utils/Requests/OnTrack';

// Maybe rename compont to On-Track Nutrition Tool? 

const ProactiveNutrition = (props) => {
    const nutritionContext = useContext(NutritionContext);
    const globalUIContext = useContext(GlobalUIContext);

    const [open, setOpen] = useState(false);
    const [confOpen, setConfOpen] = useState(false);
    const [date, setDate] = useState("");
    const [adjustmentData, setAdjustmentData] = useState([]);

    const calculateOnTrackAdjustments = async (data, date) => {
        const successCallback = (res) => {
            setAdjustmentData(res.data.adjustmentData);
            setDate(date);
            setOpen(false);
            setConfOpen(true);
            globalUIContext.setLoading(false);
        }

        const errorCallback = (err) => {
            globalUIContext.setLoading(false);
            toast.error("There was a problem calculating your On Track adjustments. Please refresh the page and try again.");
        }

        globalUIContext.setLoading(true);
        await calcOTAdjustments(data, errorCallback, successCallback);
    }

    const confirmAdjustments = async () => {
        const successCallback = (res) => {
            setAdjustmentData([]);
            setOpen(false);
            setConfOpen(false);
            toast.success("Your On-Track adjustments have been saved.");
            getOnTrackAdjustments();
            globalUIContext.setLoading(false);
        }

        const errorCallback = (err) => {
            globalUIContext.setLoading(false);
            toast.error("There was a problem calculating your On Track adjustments. Please refresh the page and try again.");
        }

        let data = {
            adjustments: adjustmentData,
            offendingDate: date
        }

        globalUIContext.setLoading(true);
        await confirmOTAdjustments(data, errorCallback, successCallback);
    }

    const getOnTrackAdjustments = async () => {
        await nutritionContext.getOnTrackAdjustments();
    }

    useEffect(() => {
        if(nutritionContext.state.upcomingAdjustments.length === 0) {
            nutritionContext.getOnTrackAdjustments();
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="card on-track-card">
                <div className="on-track-card__main">
                    <div className="on-track-card__content">
                        <div className="on-track-card__icon">
                            <div className="sidebar-item__icon">
                                <svg>
                                    <use xlinkHref="/images/icon-library.svg#nutrition" />
                                </svg>
                            </div>
                            <div className="on-track-card__title">Stay On Track</div>    
                        </div>
                        <p>Following a nutrition plan doesn't mean missing out on meals with friends, nights out or just having your favourite take-away! With some smart forward thinking we can help you stay on track whilst still enjoying yourself.</p>
                        <br />
                        <p>Had an unexpected night out or binge? We can also help get you back on track and minimise the impact.</p>
                    </div>
                    <div className="on-track-card__action">
                        <div>
                            <button className="btn btn--white" onClick={() => setOpen(true)}>Keep me on Track</button>
                            <Link to="/knowledge">Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card nutrition-target">
                <div className="nutrition-target__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition" />
                        </svg>
                    </div>
                    <div className="nutrition-target__title">Upcoming On Track Adjustments</div>    
                </div>
                <div className="nutrition-target__content">
                    {nutritionContext.state.upcomingAdjustments.length > 0 ?
                        <>
                            {nutritionContext.state.upcomingAdjustments.map((adjustment, index) => 
                                <div className="adjustment-row" key={index}>
                                    <div className="adjustment-row__date">{moment(adjustment.date).format("DD/MM/YYYY")}</div>
                                    <div className="adjustment-row__value">
                                        <span>Adjustment</span>
                                        <span>{adjustment.adjustment.adjustment}kcals</span>
                                    </div>
                                    <div className="adjustment-row__value">
                                        <span>Target</span>
                                        <span>{adjustment.adjustment.target}kcals</span>
                                    </div>
                                    <div className="adjustment-row__value">
                                        <span>Protein</span>
                                        <span>{adjustment.adjustment.protein}g</span>
                                    </div>
                                    <div className="adjustment-row__value">
                                        <span>Carbs</span>
                                        <span>{adjustment.adjustment.carbohydrates}g</span>
                                    </div>
                                    <div className="adjustment-row__value">
                                        <span>Fats</span>
                                        <span>{adjustment.adjustment.fats}g</span>
                                    </div>
                                </div>
                            )}
                        </>
                    :
                        <div className="adjustment-row">
                            <div className="adjustment-row__date">
                                You do not have any upcoming adjustments.
                            </div>
                        </div>
                    }
                </div>
            </div>
            <ProactiveNutritionDrawer open={open} closeDrawer={() => setOpen(false)} calculateAdjustments={(data, date) => calculateOnTrackAdjustments(data, date)} />
            <ProactiveNutritionConfirmation open={confOpen && adjustmentData.length > 0} adjustmentData={adjustmentData} closeConfirmation={() => setConfOpen(false)} confirmAdjustments={() => confirmAdjustments()} />
        </>
    );
}

export default ProactiveNutrition;