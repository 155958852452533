import React, { useContext, useState, useEffect } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import LogSet from './LogSet';
import LogSuperSet from './LogSuperSet';
import ExerciseModal from '../ExerciseModal/ExerciseModal';
import LogNote from './LogNote';

// Utils
import { exerciseDataById } from '../../Utils/Exercise/Exercise';

const LogBody = ({ exercise, log, logGhost }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const [imageLoading, setImageLoading] = useState(true);
    const [exerciseModal, setExerciseModal] = useState(false);
    const { name, description, image, video, _id } = exerciseDataById(stateStoreContext.state.exercises, exercise.exerciseId);

    useEffect(() => {
        setImageLoading(true);
    }, [exercise]);

    return (
        <>
            <ExerciseModal open={exerciseModal} exercise={{name, description, image, video, _id}} handleClose={() => setExerciseModal(false)} />
            <div className="log-exercise-details">
                <div className="log-exercise-details__image" onClick={() => setExerciseModal(true)}>
                    <img src={image} alt={name} onLoad={() => setImageLoading(false)} />
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#play" />
                    </svg>
                    {imageLoading && <div className="image-loader"></div>}
                </div>
                <div className="log-exercise-details__content">
                    <p className="log-exercise-details__title">{name}</p>
                    <p className="log-exercise-details__desc">{description}</p>
                </div>
            </div>
            <hr />
            {log.superset ?
                <>
                {log.sets.map((set, index) => 
                    <LogSuperSet set={set} supersetData={log.additionalExercises} target={exercise.sets[index]} index={index + 1} additionalExercises={exercise.additionalExercises} exerciseid={exercise.id} logGhost={logGhost.sets[index]} logGhostSuperset={logGhost.additionalExercises} key={index} />
                )}
                </>
            :
                <>
                {log.sets.map((set, index) => 
                    <LogSet set={set} target={exercise.sets[index]} index={index + 1} exerciseid={exercise.id} logGhost={logGhost.sets[index]} key={index} />
                )}
                </>
            }
            <LogNote exerciseid={exercise.id} notes={log.notes} />
        </>
    )
}

export default LogBody;