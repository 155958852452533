import React from 'react';

const WorkoutEditorToolbar = ({ meta, addDay, saveWorkoutPlan }) => {
    return (
        <div className="workout-editor-toolbar">
            <div className="ml-auto">
                <button className="btn" onClick={() => addDay()}>Add Day +</button>
                <button className="btn" onClick={() => addDay(true)}>Add Rest Day +</button>
                <button className="btn" onClick={() => saveWorkoutPlan()}>Save Workout</button>
            </div>
        </div>
    );
}

export default WorkoutEditorToolbar;