import React from 'react';

// Utils
import { titleCase } from '../../Utils/Manipulation/Manipulation';

const ExerciseModal = ({ open, exercise, handleClose }) => {
    return (
        <div className={`overlay ${open ? 'is-open' : ''}`}>
            {Object.entries(exercise).length !== 0 && exercise.constructor === Object ?
                <div className="exercise-modal-container">
                    <div className="exercise-modal">
                        <div className="exercise-modal__media">
                            {exercise.video ? 
                            <iframe src={exercise.video} />
                            :
                            'Coming soon'
                            }
                        </div>
                        <div className="exercise-modal__content">
                            <h2 className="exercise-modal__title">{titleCase(exercise.name)}</h2>
                            <p>{exercise.description}</p>
                        </div>
                        <div className="exercise-modal__close">
                            <button className="icon-btn" onClick={() => handleClose()}>
                                <svg>
                                    <use xlinkHref="/images/icon-library.svg#close" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            :
            ''
            }
        </div>
    );
}

export default ExerciseModal;