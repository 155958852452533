import React from 'react';

const WorkoutEditorSetCardio = ({ set, index, setKey, day, exercise, handleChange, removeSet, parentId = false }) => {
    return (
        <div className="workout-editor-set">
            <div className="workout-editor-set__title">
                <span>Set {index + 1}{exercise.superset || parentId !== false ? setKey : ''}</span>
                <select name="type" value={set.type} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)}>
                    <option value="steady state">Steady State</option>
                    <option value="hiit">HIIT</option>    
                </select>    
            </div>
            <div className="workout-editor-set__reps">
                <span>Duration</span>
                <input type="number" name="duration" value={set.duration} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
            </div>
            <div className="workout-editor-set__weight">
                <span>Pace</span>
                <input type="number" name="pace" value={set.pace} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
                {set.type === "hiit" &&
                    <>
                        <span>On/Off</span>
                        <input type="number" name="on" value={set.on} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
                        -
                        <input type="number" name="off" value={set.off} onChange={(e) => handleChange(day.id, exercise.id, set.id, e, parentId ? true : false, parentId)} />
                    </>
                }
            </div>
            <div className="workout-editor-set__actions">
                {parentId === false &&
                <button className="icon-btn" onClick={() => removeSet(day.id, exercise.id, set.id)}>
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#close" />
                    </svg>
                </button>
                }
            </div>
        </div>
    );
}

export default WorkoutEditorSetCardio;