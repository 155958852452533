import React, { useContext } from 'react';
import WorkoutOverviewDay from './WorkoutOverviewDay';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { LogContext } from '../../Contexts/LogContext/LogContext';

const WorkoutOverview = ({ welcome = false, plan }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const logContext = useContext(LogContext);

    return (
        <>
        {stateStoreContext.state.exercises.length > 0 &&
            <>
                <div className="progress-gallery__title">
                    <h3>My Workout Plan</h3>
                    <div className="ml-auto">
                        <button>Some options</button>
                    </div>
                </div>
                <div className="workout-container">
                    {welcome ?
                        <>
                        {plan.map((day, index) => 
                            <WorkoutOverviewDay key={index} day={day} />    
                        )}
                        </>
                    :
                        <>
                        {stateStoreContext.state.plan.workout.workoutuser && stateStoreContext.state.plan.workout.workoutuser.data.map((day, index) => 
                            <WorkoutOverviewDay key={index} day={day} resetLog={() => logContext.resetLog()} />    
                        )}
                        </>
                    }
                </div>
            </>
        }
        </>
    );
}

export default WorkoutOverview;