import React, { useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';

// Components
import WorkoutStatsChartVolume from './WorkoutStatsChartVolume';
import WorkoutStatsChartWorkouts from './WorkoutStatsChartWorkouts';
import WorkoutStatsChartTime from './WorkoutStatsChartTime';
import WorkoutStatsChartAvg from './WorkoutStatsChartAvg';

// Utils
import { getLogsBetween } from '../../Utils/Requests/Log';

const WorkoutStatsChart = (props) => {
    const [metric, setMetric] = useState(0); // 0: Volume, 1: workout count, 2: time, 3: avg. time
    const [period, setPeriod] = useState(0); // 0: daily, 1: weekly, 2: monthly
    const [periodType, setPeriodType] = useState(0); // 0: daily, monthly & yearly, 1: month & yearly
    const [startDate, setStartDate] = useState(moment().subtract(1, "month").toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [logs, setLogs] = useState([]);

    const fetchLogs = async () => {
        const successCallback = (logs) => {
            setLogs(logs);
        }

        const errorCallback = (err) => {
            toast.error("There was an issue retrieving log data. Please refresh and try again.");
        }

        await getLogsBetween(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), errorCallback, successCallback);
    }

    useEffect(() => {
        fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
        if(period === 2) {
            let start = moment(startDate).date(1).toDate(),
                end = moment(endDate).add(1, 'months').date(1).subtract(1, 'days').toDate();

            setStartDate(start);
            setEndDate(end);
        }
    }, [period]);

    useEffect(() => {
        switch(metric) {
            case 0:
                setPeriodType(0);
                break;
            case 1:
            case 3:
                    setPeriod(1);
                    setPeriodType(1);
                    break;
            default:
                setPeriodType(0);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metric]);

    return (
        <div className="complex-chart card">
            <div className="complex-chart__nav">
                <button className={`pill-btn ${metric === 0 ? 'is-active' : ''}`} onClick={() => setMetric(0)}>Volume</button>
                <button className={`pill-btn ${metric === 1 ? 'is-active' : ''}`} onClick={() => setMetric(1)}>Workouts</button>
                <button className={`pill-btn ${metric === 2 ? 'is-active' : ''}`} onClick={() => setMetric(2)}>Time</button>
                <button className={`pill-btn ${metric === 3 ? 'is-active' : ''}`} onClick={() => setMetric(3)}>Avg. Time</button>
            </div>
            <div className="complex-chart__main">
                <div className="complex-chart__selection">
                    <select value={period} onChange={(e) => setPeriod(parseInt(e.target.value))}>
                        {periodType === 0 && 
                            <option value={0}>Daily</option>
                        }
                        <option value={1}>Weekly</option>
                        <option value={2}>Monthly</option>
                    </select>
                    <div className="complex-chart__datepicker">
                        {period !== 2 ?
                            <>
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                dateFormat="MM/yyyy"
                                withPortal
                                selectsStart
                                maxDate={moment(endDate).subtract(1, "day").toDate()}
                                customInput={<button className="btn">{moment(startDate).format("DD")} {moment(startDate).format("MMMM")} {moment(startDate).format("YYYY")}</button>} />
                            <span> to </span>
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                dateFormat="MM/yyyy"
                                withPortal
                                selectsEnd
                                maxDate={new Date()}
                                minDate={moment(startDate).add(1, "day").toDate()}
                                customInput={<button className="btn">{moment(endDate).format("DD")} {moment(endDate).format("MMMM")} {moment(endDate).format("YYYY")}</button>} />
                            </>
                        :
                        <>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat="MM/yyyy"
                            withPortal
                            selectsStart
                            showMonthYearPicker
                            maxDate={moment(endDate).subtract(1, "day").toDate()}
                            customInput={<button className="btn">{moment(startDate).format("MMMM")} {moment(startDate).format("YYYY")}</button>} />
                        <span> to </span>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            dateFormat="MM/yyyy"
                            withPortal
                            selectsEnd
                            showMonthYearPicker
                            maxDate={new Date()}
                            minDate={moment(startDate).add(1, "day").toDate()}
                            customInput={<button className="btn">{moment(endDate).format("MMMM")} {moment(endDate).format("YYYY")}</button>} />
                            </>
                        }
                    </div>
                </div>
                {metric === 0 && 
                    <WorkoutStatsChartVolume logData={logs} period={period} startDate={startDate} endDate={endDate} />
                }
                {metric === 1 &&
                    <WorkoutStatsChartWorkouts logData={logs} period={period} startDate={startDate} endDate={endDate} />
                }
                {metric === 2 && 
                    <WorkoutStatsChartTime logData={logs} period={period} startDate={startDate} endDate={endDate} />
                }
                {metric === 3 && 
                    <WorkoutStatsChartAvg logData={logs} period={period} startDate={startDate} endDate={endDate} />
                }
            </div>
        </div>
    );
}

export default WorkoutStatsChart;