import React from 'react';
import {
    Area, AreaChart, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
  } from 'recharts';
import moment from 'moment';

// Utils
import { titleCase } from '../../Utils/Manipulation/Manipulation';

const AreaChartCard = ({ icon, title, data, dataKey, toolTipValue, selectionChange }) => {

    const tooltipFormat = (value) => {
        // TODO: Display unit depending on users set unit preference
        return value + toolTipValue;
    }

    return (
        <div className="card chart-card">
            <div className="chart-card__icon">
                <div className="sidebar-item__icon">
                    <svg>
                        <use xlinkHref={`/images/icon-library.svg#${icon}`} />
                    </svg>
                </div>
                <p className="chart-card__title">{title}</p>
                <div className="chart-card__selector">
                    <select onChange={(e) => selectionChange(e.target.value)}>
                        <option value="1">Last 30 days</option>
                        <option value="6">Last 6 months</option>
                        <option value="12">Last 12 months</option>
                    </select>
                </div>
            </div>
            <div className="chart-card__main">
                <ResponsiveContainer width="100%" height={250}>
                    <AreaChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                        top: 5, right: 0, left: 0, bottom: 0,
                        }}
                        >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#FFFFFF" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="date" />
                        {/* <YAxis /> */}
                        <Tooltip formatter={tooltipFormat}
                                 itemStyle={{ color: '#000' }}
                                 labelFormatter={(value) => <b>{moment(value).format("Do MMM YYYY")}</b>} />
                        <Legend />
                        <Area dot={{ stroke: '#FFFFFF', strokeWidth: 2, fill: '#1cb5e0'}}
                                activeDot={{ stroke: '#1cb5e0', fill: '#1cb5e0' }} 
                                name={titleCase(dataKey)} 
                                type='monotone' 
                                dataKey={dataKey} 
                                stroke='#FFFFFF' 
                                strokeWidth="3" 
                                fill="url(#colorUv)"
                                connectNulls />
                    </AreaChart>
                </ResponsiveContainer>
             </div>
        </div>
    );
}

export default AreaChartCard;