import React, { createContext, useState, useEffect, useContext } from 'react';

// Contexts
import { StateStoreContext } from '../StateStoreContext/StateStoreContext';

// Utils
import { getCheckIns, saveCheckIn } from '../../Utils/Requests/CheckIn';

const CheckInContext = createContext();

const CheckInProvider = ({ children }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const [checkIns, setCheckIns] = useState([]);
    const [completeStats, setCompleteStats] = useState({});
    const [adjustment, setAdjustment] = useState({ open: false, data: {} });

    useEffect(() => {
        if(stateStoreContext.state.isLoggedIn) {
            getCheckInList(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateStoreContext.state.isLoggedIn]);

    useEffect(() => {
        getCompleteStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkIns]);

    const getCheckInList = async (months) => {
        let checkIns = await getCheckIns(months);

        checkIns.forEach(checkin => {
            Object.keys(checkin).forEach(function(key) {
               if(checkin[key] === 0) {
                   checkin[key] = null;
               }
            });
        });

        setCheckIns(checkIns);
    }

    const saveNewCheckIn = async (data, callBack) => {
        const errorCallback = (type, message) => {
            alert(type, message);
        }

        const successCallback = (response) => {
            setCheckIns([...checkIns, response.data.checkin])
            callBack("success", response.data);

            if(response.data.adjustment) {
                stateStoreContext.setPlan(response.data.plan);
                setAdjustment({ open: true, data: response.data.adjustmentData });
            }
        }

        await saveCheckIn(data, stateStoreContext.state.plan.goal, errorCallback, successCallback);
    }

    const getCompleteStats = () => {
        const measurements = ["weight", "neck", "arm", "chest", "waist", "glutes", "thigh", "calf"];
        let listLength = checkIns.length,
            completeObj = {...checkIns[listLength - 1]},
            incomplete = [];

        measurements.forEach(measurement => {
            if(completeObj[measurement] === 0 || completeObj[measurement] === null) {
                incomplete.push(measurement);
            }
        });

        if(incomplete.length > 0) {
            for(let i = listLength - 2; i > -1; i--) {
                incomplete.forEach(item => {
                    if(checkIns[i][item] !== 0 || checkIns[i][item] !== null) {
                        completeObj[item] = checkIns[i][item];
                    }
                });
                
                let checkComplete = true;

                measurements.forEach(measurement => {
                    if(completeObj[measurement] === 0 || completeObj[measurement] === null) {
                        checkComplete = false;
                    }
                });

                if(checkComplete) {
                    i = -1;
                }
            }
        }

        setCompleteStats(completeObj);
    }

    return (
        <CheckInContext.Provider value={{
            state: {
                checkIns,
                completeStats,
                adjustment
            },
            getCheckInList,
            saveNewCheckIn,
            setAdjustment
            }}>
            {children}
        </CheckInContext.Provider>
    );
}

const CheckInConsumer = CheckInContext.Consumer;

export { CheckInContext, CheckInProvider, CheckInConsumer }
