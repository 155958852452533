import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';

// Components
import IconDropdown from '../IconDropdown/IconDropdown';
import WorkoutEditorExercise from './WorkoutEditorExercise';

const WorkoutEditorDay = ({ data, meta, index, exercises, handleChange, removeDay, toggleSelector, removeExercise, addSet, removeSet, addSuperset, onDropExercise, handleSetDataChange }) => {
    return (
        <>
                {data.rest ?
                    <div className="workout-editor-day">
                        <div className="workout-editor-day__header">
                            <span>Rest Day</span>
                            <div className="ml-auto">
                                <IconDropdown icon="options" location="right">
                                    <a href="#0" onClick={(e) => removeDay(data.id, e)}>Remove</a>
                                </IconDropdown>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="workout-editor-day">
                        <div className="workout-editor-day__header">
                            <input type="text" name="name" value={data.name} placeholder="Day name" onChange={(e) => handleChange(data.id, e)} />
                            {meta.scheduleType === "day" &&
                                <div className="workout-editor-day__day-picker">
                                    <p>Day </p>
                                    <select name="specificDay" value={data.specificDay} onChange={(e) => handleChange(data.id, e)}>
                                        <option value={null}>Please select a day</option>
                                        <option value="monday">Monday</option>
                                        <option value="tuesday">Tuesday</option>
                                        <option value="wednesday">Wednesday</option>
                                        <option value="thursday">Thursday</option>
                                        <option value="friday">Friday</option>
                                        <option value="saturday">Saturday</option>
                                        <option value="sunday">Sunday</option>
                                    </select>
                                </div>
                            }
                            <div className="ml-auto">
                                <IconDropdown icon="options" location="right">
                                    <a href="#0" onClick={(e) => removeDay(data.id, e)}>Remove</a>
                                </IconDropdown>
                            </div>
                        </div>
                        <div className="workout-editor-day__main">
                            {data.exercises.length > 0 ?
                                <Container onDrop={(result) => onDropExercise(result, data.id)}>
                                    {data.exercises.map((exercise, index) => {
                                    return <Draggable key={exercise.id}>
                                                <WorkoutEditorExercise
                                                    key={index}
                                                    index={index}
                                                    exercise={exercise}
                                                    exercises={exercises}
                                                    day={data}
                                                    removeExercise={(dayid, exerciseid, superset, parentId) => removeExercise(dayid, exerciseid, superset, parentId)} 
                                                    addSet={(dayid, exerciseid, eid, superset) => addSet(dayid, exerciseid, eid, superset)}
                                                    removeSet={(dayid, exerciseid, setid) => removeSet(dayid, exerciseid, setid)}
                                                    addSuperset={(dayid, exerciseid) => addSuperset(dayid, exerciseid)} 
                                                    handleSetDataChange={(dayid, exerciseid, setid, e, supersetitem, parentId) => handleSetDataChange(dayid, exerciseid, setid, e, supersetitem, parentId)} />
                                            </Draggable>
                                    }
                                    )}
                                </Container>
                            :
                                <button className="sidebar-item sidebar-item--alt" onClick={() => toggleSelector()}>
                                    <div className="sidebar-item__icon">
                                        <svg>
                                            <use xlinkHref="/images/icon-library.svg#plus" />
                                        </svg>
                                    </div>
                                    <span className="sidebar-item__text">Add Exercises</span>
                                </button>
                            }
                        </div>
                    </div>
                    }
        </>
    );
}

export default WorkoutEditorDay;
