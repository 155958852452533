export const getExerciseData = async (errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/exercise`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                if(res.data.length > 0) {
                    successCallback(res.data);
                } 
            }
        })
        .catch(err => {
            errorCallback(err);
        });
}