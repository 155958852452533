import React, { useState } from 'react';

// Utils
import { titleCase } from '../../Utils/Manipulation/Manipulation';

const VideoLibraryGrid = ({ videos, handleExerciseSelect }) => {
    const [imageLoading, setImageLoading] = useState(true);

    return (
        <div className="video-library-grid">
            {videos.length > 0 ?
            <>
            {videos.map(video => 
                <div key={video._id} className="video-item" onClick={() => handleExerciseSelect(video)}>
                    <div className={`video-item__image video-item__image--none`}>
                        {video.image ?
                            <>
                            <img src={video.image} alt={video.name} onLoad={() => setImageLoading(false)} />
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#play" />
                            </svg>
                            {imageLoading && <div className="image-loader"></div>}
                            </>
                        :
                            <div>Image Coming Soon</div>
                        }
                    </div>
                    <div className="video-item__title">
                        {titleCase(video.name)}
                    </div>
                </div>
            )}
            </>
            :
            <>
            <p className="video-not-found">No exercises found matching your search criteria.</p>
            </>
            }
            </div>
    );
}

export default VideoLibraryGrid;