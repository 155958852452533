import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

// Components
import ActivityCalendarSpacer from './ActivityCalendarSpacer';
import ActivityCalendarDay from './ActivityCalendarDay';

// Utils
import { getLogsMonth } from '../../Utils/Requests/Log';

const ActivityCalendar = (props) => {
    const [calendar, setCalendar] = useState([]);
    const [month, setMonth] = useState(false);
    const [year, setYear] = useState(false);
    const [loading, setLoading] = useState(false);

    const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    useEffect(() => {
        setMonth(moment().format("MMMM"));
        setYear(moment().format("YYYY"));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateCalendar();
    }, [month, year]);

    const generateCalendar = async () => {
        setLoading(true);
        setCalendar([]);

        if(month && year) {

            const startDay = moment(`01-${month}-${year}`, "DD-MMMM-YYYY").format("dddd"),
                  daysInMonth = moment(`01-${month}-${year}`, "DD-MMMM-YYYY").daysInMonth();
      
            let logs = await getLogsMonth(month, year);

            let i = weekDays.indexOf(startDay),
                calendarData = [];

                for(let j = 0; j < i; j++) {
                calendarData.push(<ActivityCalendarSpacer key={j} />);
            }

            for(let k = 1; k < daysInMonth + 1; k++) {
                let data = [];

                logs.forEach(log => {
                    if(parseInt(moment(log.date).format("D")) === k) {
                        data.push(log);
                    }
                });

                calendarData.push(<ActivityCalendarDay date={k} data={data} key={`d${k}`} />)
            }

            setCalendar(calendarData);
            setLoading(false);
        } else {
            setCalendar([]);
            setLoading(false);
        }
    }

    const handleChange = (date) => {
        let selectedMonth = moment(date).format("MMMM"),
            selectedYear = moment(date).format("YYYY");

        setMonth(selectedMonth);
        setYear(selectedYear);
    }

    return (
        <div className="card activity-calendar">
                {loading && <div className={`image-loader ${loading ? 'is-loading' : ''}`}></div>}
                <div className="activity-calendar__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#calendar" />
                        </svg>
                    </div>
                    <p className="activity-calendar__title">My Activity</p>
                    <div className="activity-calendar__datepicker">
                        <DatePicker
                            // selected={new Date(`${year}-${month}-01`)}
                            selected={moment(`${year}-${month}-01`, 'YYYY-MMMM-DD').toDate()}
                            onChange={date => handleChange(date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            withPortal
                            maxDate={new Date()}
                            customInput={<button className>{month} {year}</button>}
                            />
                    </div>    
                </div>
                <div className="activity-calendar__main">
                    <div className="calendar">
                        <div className="calendar__days">
                            <div className="calendar__day">M</div>
                            <div className="calendar__day">T</div>
                            <div className="calendar__day">W</div>
                            <div className="calendar__day">T</div>
                            <div className="calendar__day">F</div>
                            <div className="calendar__day">S</div>
                            <div className="calendar__day">S</div>
                        </div>
                        <div className="calendar__grid">
                            {calendar}
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default ActivityCalendar;