import React, { useState, useContext, useEffect } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import ExerciseSelectorItem from './ExerciseSelectorItem';
import ExerciseSelectorBasketItem from './ExerciseSelectorBasketItem';

const ExerciseSelector = ({ open, superset, exerciseid, toggleSelector, exercises, dayid }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const [selected, setSelected] = useState([]);
    
    const selectExercise = (exerciseid) => {
        let currentSelected = [...selected];

        if(currentSelected.includes(exerciseid)) {
            currentSelected = currentSelected.filter(selected => selected !== exerciseid);
        } else {
            currentSelected.push(exerciseid);
        }

        setSelected(currentSelected);
    }

    const submitSelected = () => {
        let selectedItems = [...selected];
        setSelected([]);
        toggleSelector(dayid, "close", selectedItems, superset, exerciseid);
    }

    return (
        <>
        <div className={`overlay ${open ? 'is-open' : ''}`}>
            <div className={`exercise-selector ${open ? 'is-open': ''}`}>
                <div className="exercise-selector__main">
                    <div className="exercise-selector__header">
                        <h2>Exercise Selector</h2>
                    </div>
                    <div className="exercise-selector__list">
                        <>
                            {stateStoreContext.state.exercises.length > 0 ? 
                                <>
                                {stateStoreContext.state.exercises.map((exercise, index) =>
                                    <ExerciseSelectorItem key={index} exercise={exercise} selected={selected.includes(exercise._id)} onSelect={(exerciseid) => selectExercise(exerciseid)} />
                                )}
                                </>
                                :
                                <p className="p-1">There are no exercises available.</p>
                            }
                        </>
                    </div>
                </div>
                <div className="exercise-selector__sidebar">
                    <div className="exercise-selector__header">
                        <h2>Selected</h2>
                    </div>
                    <div className="exercise-selector__list exercise-selector__list--basket">
                        {selected.length > 0 ?
                            <>
                            {selected.map((exerciseid, index) =>
                                <ExerciseSelectorBasketItem key={index} exerciseid={exerciseid} remove={(exerciseid) => selectExercise(exerciseid)} />   
                            )}
                            </>
                        :
                            <p className="p-1">No exercises selected</p>
                        }
                    </div>
                    <div className="exercise-selector__footer">
                        <button className="btn" disabled={selected.length === 0} onClick={() => submitSelected()}>Select</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default ExerciseSelector;