import React, { createContext, useState } from 'react';

const GlobalUIContext = createContext();

const GlobalUIProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);
    const [workoutTab, setWorkoutTab] = useState("workout");

    return (
        <GlobalUIContext.Provider value={{
            state: {
                loading,
                mobileNav,
                workoutTab
            },
            setLoading,
            setMobileNav,
            setWorkoutTab
            }}>
            {children}
        </GlobalUIContext.Provider>
    );
}

const GlobalUIConsumer = GlobalUIContext.Consumer;

export { GlobalUIContext, GlobalUIProvider, GlobalUIConsumer }
