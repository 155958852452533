import React from 'react';

const StatisticsCard = ({ data, view, onStatSelect }) => {
    return (
        <div className="card statistic-card">
            {data ? 
                <>
                    <div className={`statistic-card__item ${view === "weight" ? 'is-active' : ''}`} onClick={() => onStatSelect("weight")}>
                        <div className="statistic-card__title">Bodyweight</div>
                        <div className="statistic-card__stat"><p>{data.weight && data.weight !== 0 ? data.weight : '--'}<span>KG</span></p></div>
                    </div>
                    <div className={`statistic-card__item ${view === "neck" ? 'is-active' : ''}`} onClick={() => onStatSelect("neck")}>
                        <div className="statistic-card__title">Neck</div>
                        <div className="statistic-card__stat"><p>{data.neck && data.neck !== 0 ? data.neck : '--'}<span>CM</span></p></div>
                    </div>
                    <div className={`statistic-card__item ${view === "arm" ? 'is-active' : ''}`} onClick={() => onStatSelect("arm")}>
                        <div className="statistic-card__title">Arm</div>
                        <div className="statistic-card__stat"><p>{data.arm && data.arm !== 0 ? data.arm : '--'}<span>CM</span></p></div>
                    </div>
                    <div className={`statistic-card__item ${view === "chest" ? 'is-active' : ''}`} onClick={() => onStatSelect("chest")}>
                        <div className="statistic-card__title">Chest</div>
                        <div className="statistic-card__stat"><p>{data.chest && data.chest !== 0 ? data.chest : '--'}<span>CM</span></p></div>
                    </div>
                    <div className={`statistic-card__item ${view === "waist" ? 'is-active' : ''}`} onClick={() => onStatSelect("waist")}>
                        <div className="statistic-card__title">Waist</div>
                        <div className="statistic-card__stat"><p>{data.waist && data.waist !== 0 ? data.waist : '--'}<span>CM</span></p></div>
                    </div>
                    <div className={`statistic-card__item ${view === "glutes" ? 'is-active' : ''}`} onClick={() => onStatSelect("glutes")}>
                        <div className="statistic-card__title">Glutes</div>
                        <div className="statistic-card__stat"><p>{data.glutes && data.glutes !== 0 ? data.glutes : '--'}<span>CM</span></p></div>
                    </div>
                    <div className={`statistic-card__item ${view === "thigh" ? 'is-active' : ''}`} onClick={() => onStatSelect("thigh")}>
                        <div className="statistic-card__title">Thigh</div>
                        <div className="statistic-card__stat"><p>{data.thigh && data.thigh !== 0 ? data.thigh : '--'}<span>CM</span></p></div>
                    </div>
                    <div className={`statistic-card__item ${view === "calf" ? 'is-active' : ''}`} onClick={() => onStatSelect("calf")}>
                        <div className="statistic-card__title">Calf</div>
                        <div className="statistic-card__stat"><p>{data.calf && data.calf !== 0 ? data.calf : '--'}<span>CM</span></p></div>
                    </div>
                </>    
            :
                <>

                </>
            }
        </div>
    );
}

export default StatisticsCard;