export const getUserDetails = async () => {
    let userObj = await fetch(`${window.API_ENDPOINT}/account/`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                return res.data.user;
            }
        })
        .catch(err => {
            console.log(err);
        });

    return userObj;
}

export const getProfileDetails = async () => {
    let profileObj = await fetch(`${window.API_ENDPOINT}/account/profile`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                return res.data.profile;
            }
        })
        .catch(err => {
            console.log(err);
        });

    return profileObj;
}

export const completeWelcome = async () => {
    let profileObj = await fetch(`${window.API_ENDPOINT}/account/welcome`, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                return res;
            }
        })
        .catch(err => {
            console.log(err);
        });

    return profileObj;
}

export const updateAvatar = async (data, errorCallback, successCallback) => {
    let response = await fetch('http://localhost:3000/media/avatar', {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            body: data
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                // setProfile(res.data.profile);
                successCallback(res.data.user);
            } else if (res.storageErrors) {
                console.log("invalid file type");
                // TODO: Error handle
                console.log(res);
                console.log("handle error here");
                errorCallback(res);
            }
        })
        .catch(err => {
            console.log(err);
            // TODO: Error handle
            console.log("handle error here");
            errorCallback(err);
        });

        return response;
}

export const updateUserDetails = async (details, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/account`, {
        method: 'PUT',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(details)
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            successCallback(res.data.user);
        }
    })
    .catch(err => {
        console.log(err);
        errorCallback(err);
    });

}