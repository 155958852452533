import React, { useEffect, useState, useContext } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import WorkoutEditor from '../../Components/WorkoutEditor/WorkoutEditor';

const WorkoutEdit = ({ match: { params }}) => {
    const stateStoreContext = useContext(StateStoreContext);

    const [workout, setWorkout] = useState(false);
    useEffect(() => {
        // Get workout data
        // Decide how/what to retrieve, workouttemplate or users workout?
        // Temporarily just edit users workout in state context just now
        setWorkout(stateStoreContext.state.plan.workout.workoutuser);
    }, []);

    return (
        <>
            {workout &&
                <WorkoutEditor workout={workout} />
            }
        </>
    );
}

export default WorkoutEdit;