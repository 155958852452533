import React, { useContext } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext'; 

// Utils
import { exerciseDataById } from '../../Utils/Exercise/Exercise';

const WorkoutOverviewExercise = ({ exercise, supersetItem }) => {
    const stateStoreContext = useContext(StateStoreContext);

    const { name, type, image } = exerciseDataById(stateStoreContext.state.exercises, exercise.exerciseId);

    return (
        <>
        <div className={`workout-day__row ${supersetItem ? 'is-superset' : ''}`}>
            <div className="thumbnail mr-1">
                <img src={image} alt={name} />
            </div>
            <b>{name}</b>
            <span className="ml-auto">
                {type === "resistance" ?
                <>
                    {exercise.sets.length} Sets
                </>
                :
                <>
                    {exercise.sets[0].duration / 60} Minutes
                </>
                }
            </span>
        </div>
        {exercise.superset && 
        <>
            {exercise.additionalExercises.map((additional, index) =>
                <WorkoutOverviewExercise key={index} exercise={additional} supersetItem={true} />
            )}
        </>
        }
        </>
    );
}

export default WorkoutOverviewExercise;