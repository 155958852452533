import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { hmsToSeconds } from '../../Utils/Manipulation/Manipulation';

const WorkoutStatsCard = (props) => {
    const stateStoreContext = useContext(StateStoreContext);
    const [statLogs, setStatLogs] = useState([]);
    const [totalTime, setTotalTime] = useState(0);
    const [totalVolume, setTotalVolume] = useState(0);

    useEffect(() => {
        let logs = [...stateStoreContext.state.logs],
            currentMonth = moment().format("MMMM");

        // 1. Filter to only logs for this month
        logs = logs.filter(log => {
            let month = moment(log.date).format("MMMM");

            return month === currentMonth;
        });

        // 2. Calculate total time + volume lifter
        let totalTime = 0,
            totalVolume = 0;

        logs.forEach(log => {
            let logTotal = 0;
            totalTime = totalTime + hmsToSeconds(log.duration);

            log.data.exercises.forEach(exercise => {
                let exerciseTotal = 0;

                exercise.sets.forEach(set => {
                    if(set.type === "weight") {
                        exerciseTotal = (set.weight * set.reps) + exerciseTotal;
                    }
                });

                logTotal = logTotal + exerciseTotal;
            });

            totalVolume = totalVolume + logTotal;
        });

        setTotalTime(moment().startOf('day').seconds(totalTime).format('H[h] mm[m] ss[s]'));
        setTotalVolume(totalVolume);

        // 3. Check for achievements
        // TODO: Achievement functionality not yet implemented

        // 4. Set stat logs
        setStatLogs(logs);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="workout-stat-card card">
            <div className="workout-stat-card__icon">
                <div className="sidebar-item__icon">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#dumbbell" />
                    </svg>
                </div>
                <p className="workout-stat-card__title">This Month</p>
            </div>
            <div className="workout-stat-card__content">
                {statLogs.length > 0 ? 
                    <>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                {statLogs.length}
                            </div>
                            <div className="stat-item__key">
                                workouts completed
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#dumbbell" />
                            </svg>
                        </div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                {totalTime}
                            </div>
                            <div className="stat-item__key">
                                total time
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#stopwatch" />
                            </svg>
                        </div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                1
                            </div>
                            <div className="stat-item__key">
                                new achievement
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#trophy" />
                            </svg>
                        </div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                {totalVolume} <span>KG</span>
                            </div>
                            <div className="stat-item__key">
                                volume lifted
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#volume" />
                            </svg>
                        </div>
                    </div>
                    </>
                :
                    <>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                0
                            </div>
                            <div className="stat-item__key">
                                workouts completed
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#dumbbell" />
                            </svg>
                        </div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                0h 00m 00s
                            </div>
                            <div className="stat-item__key">
                                total time
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#stopwatch" />
                            </svg>
                        </div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                0
                            </div>
                            <div className="stat-item__key">
                                new achievement
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#trophy" />
                            </svg>
                        </div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-item__content">
                            <div className="stat-item__value">
                                0 <span>KG</span>
                            </div>
                            <div className="stat-item__key">
                                volume lifted
                            </div>
                        </div>
                        <div className="stat-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#volume" />
                            </svg>
                        </div>
                    </div>
                    </>
                }
            </div>
        </div>
    );
}

export default WorkoutStatsCard;