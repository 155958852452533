export const saveWorkout = async (data, errorCallback, successCallback) => {
    await fetch('http://localhost:3000/workout/', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({...data})
    })
    .then(res => res.json())
    .then(res => {
        successCallback(res);
    })
    .catch(err => {
        errorCallback(err);
    });
}

export const getworkout = () => {
    
}