import React from 'react';

const LogControl = ({ current, count, prevExercise, nextExercise, completeWorkout }) => {
    return (
        <div className="f-container mb-1">
            {current !== 1 &&
            <button className="icon-btn icon-btn--white" onClick={() => prevExercise()}>
                <svg>
                    <use xlinkHref="/images/icon-library.svg#chevron-left" />
                </svg>
            </button>
            }
            <div className="ml-auto">
                {current === count ?
                <button className="btn btn--icon" onClick={() => completeWorkout()}>
                    Finish Workout
                    <span>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#finish-flag" />
                        </svg>
                    </span>
                </button>
                :
                <button className="btn btn--icon" onClick={() => nextExercise()}>
                    Next
                    <span>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-right" />
                        </svg>
                    </span>
                </button>
                }
            </div>
        </div>
    );
}

export default LogControl;