import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

// Contexts
import { GlobalUIContext } from '../GlobalUIContext/GlobalUIContext';
import { StateStoreContext } from '../StateStoreContext/StateStoreContext';

// Utilities
import { welcomeObjSchema } from '../../Utils/Validation/ObjectValidation';
import { generatePlan as generatePlanRequest, createPlan } from '../../Utils/Requests/Plan';
import { completeWelcome } from '../../Utils/Requests/Account';

const WelcomeContext = createContext();

const WelcomeProvider = ({ children, history }) => {
    const globalUIContext = useContext(GlobalUIContext);
    const stateStoreContext = useContext(StateStoreContext);

    const [step, setStep] = useState(1);
    const [complete, setComplete] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [plan, setPlan] = useState({});
    const [welcomeComplete, setWelcomeComplete] = useState(false);
    const [welcomeData, setWelcomeData] = useState({
        gender: "",
        units: "metric",
        height: "",
        weight: "",
        activity: "Sedentary",
        goal: "",
        experience: "beginner",
        workoutDays: "3",
        startDate: "",
        missionStatement: ""
    });

    const nextStep = () => {
        if(step + 1 === 4) {
            setComplete(true);
            setPlan({});
        }
        setStep(step + 1);
    }

    const prevStep = () => {
        setPlan({});
        setGenerate(false);
        setComplete(false);
        setStep(step - 1);
    }

    const getStarted = async () => {

        globalUIContext.setLoading(true);

        const handleCreatePlanError = (error) => {
            alert(error);
        }

        const handleCreatePlanSuccess = (res) => {
            stateStoreContext.setPlan(res.data.plan);
            completeWelcomeStage();
        }

        // 1. Build object to pass to api
        let data = {
            goal: welcomeData.goal,
            nutrition: plan.nutrition,
            workoutTemplate: plan.workout,
            startDate: welcomeData.startDate,
            duration: 8
        };
        
        // 2. Submit data to api to create user plan
        await createPlan(data, handleCreatePlanError, handleCreatePlanSuccess);
    }

    const completeWelcomeStage = async () => {
        let complete = await completeWelcome();

        if(complete.status === 200) {
            stateStoreContext.setUser(complete.data.user);
            globalUIContext.setLoading(false);
            setWelcomeComplete(true);
            setTimeout(() => {
                history.push("/dashboard");
            }, 2000);
        } else {
            globalUIContext.setLoading(false);
            toast.error("There was a problem updating your account. Please try again.");
        }
    }

    useEffect(() => {
        const generatePlan = async () => {
            globalUIContext.setLoading(true);
            await generatePlanRequest(welcomeData, handleGeneratePlanError, handleGeneratePlanSuccess);
        }
    
        const handleGeneratePlanError = (error) => {
            globalUIContext.setLoading(false);
            toast.error("There has been an issue creating your plan. Please try again.");
        }
    
        const handleGeneratePlanSuccess = (plan) => {
            setTimeout(() => {
                globalUIContext.setLoading(false);
                setGenerated(true);
            }, 2000);

            setTimeout(() => {
                setPlan(plan.data.plan);
            }, 3000);            
        }

        const valid = async () => {
            let isValid = await welcomeObjSchema.isValid(welcomeData);
        
            if(isValid && complete && step === 4 && generate === true) {
                generatePlan();
            }
        }
        
        valid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [welcomeData, complete, generate]);

    return (
        <WelcomeContext.Provider value={{
            state: {
                step,
                welcomeData,
                generated,
                plan,
                welcomeComplete
            },
            setStep,
            setWelcomeData,
            setPlan,
            setComplete,
            setGenerate,
            setGenerated,
            nextStep,
            prevStep,
            getStarted,
            completeWelcomeStage
            }}>
            {children}
        </WelcomeContext.Provider>
    );
}

const WelcomeConsumer = WelcomeContext.Consumer;

export { WelcomeContext, WelcomeProvider, WelcomeConsumer }
