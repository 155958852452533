import React from 'react';
import { Formik, Form, Field } from 'formik';

// Utilities
import { welcome2Schema } from '../../../Utils/Validation/FormValidation';

const WelcomeForm2 = ({ initialValues, handleFormSubmit }) => {
    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    experience: initialValues.experience,
                    workoutDays: initialValues.workoutDays,
                    startDate: initialValues.startDate,
                    missionStatement: initialValues.missionStatement
                }}
                validationSchema={welcome2Schema}
                onSubmit={(details) => {
                    handleFormSubmit(details);
                }}>
                {({ errors, touched, values }) => (
                    <>
                        <Form>
                            <fieldset>
                                <label htmlFor="experience">Gym Experience</label>                                
                                <Field component="select" name="experience" id="experience">
                                    <option value="beginner">Beginner</option>
                                    <option value="intermediate">Intermediate</option>
                                    <option value="advanced">Advanced</option>
                                </Field>
                                {errors.experience && touched.experience ? <div className="error">{errors.experience}</div> : null}
                                <label htmlFor="workoutDays">How many days per week can you workout?</label>
                                <Field component="select" name="workoutDays" id="workoutDays">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                </Field>
                                {errors.workoutDays && touched.workoutDays ? <div className="error">{errors.workoutDays}</div> : null}
                                <label htmlFor="startDate">When would you like to officially start your plan?</label>
                                <Field type="date" name="startDate" id="startDate" />
                                {errors.startDate && touched.startDate ? <div className="error">{errors.startDate}</div> : ''}
                                <label htmlFor="missionStatement">Why do you want to do this? (optional)</label>
                                <Field component="textarea" name="missionStatement" id="missionStatement" />
                                {errors.missionStatement && touched.missionStatement ? <div className="error">{errors.missionStatement}</div> : ''}
                                <button className="btn btn--icon" type="submit">
                                    Continue
                                    <span>                                
                                        <svg>
                                            <use xlinkHref="/images/icon-library.svg#chevron-right" />
                                        </svg>
                                    </span>
                                </button>
                            </fieldset>
                        </Form>
                    </>
                )}
                </Formik>
        </React.Fragment>
    );
}

export default WelcomeForm2;