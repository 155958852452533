import React, { useContext, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// Contexts
import { LogContext } from '../../Contexts/LogContext/LogContext';

// Components
import LogBookItem from './LogBookItem';
import LogBookPagination from './LogBookPagination';

// Utils
import { getLogsMonth } from '../../Utils/Requests/Log';

const LogBook = (props) => {
    const logContext = useContext(LogContext);

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const getLogs = async () => {
            let month = moment(logContext.state.selectedDate).format("M"),
            year = moment(logContext.state.selectedDate).format("YYYY");

            let logsByMonth = await getLogsMonth(month, year);

            setLogs(logsByMonth);
        }


        getLogs();
        
    }, [logContext.state.selectedDate]);

    return (
        <>
            <div className="progress-gallery__title">
                <h3>Log Book</h3>
                <div className="ml-auto">
                    <DatePicker
                        selected={logContext.state.selectedDate}
                        onChange={date => logContext.setSelectedDate(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        withPortal
                        maxDate={new Date()}
                        customInput={<button className>{moment(logContext.state.selectedDate).format("MMMM")} {moment(logContext.state.selectedDate).format("YYYY")}</button>} />
                </div>
            </div>
            {logs.length > 0 ?
                <>
                {logs.map((log, index) =>
                    <LogBookItem key={index} log={log} />
                )}
                </>
                :
                <p>No workout logs for {moment(logContext.state.selectedDate).format("MMMM")}</p>
            }
        </>
    );
}

export default LogBook;