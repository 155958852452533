import React, { createContext, useContext } from 'react';

// Contexts
import { StateStoreContext } from '../StateStoreContext/StateStoreContext';
import { GlobalUIContext } from '../GlobalUIContext/GlobalUIContext';

// Utils
import { updateAvatar, updateUserDetails } from '../../Utils/Requests/Account';
import { toast } from 'react-toastify';

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
    const globalUIContext = useContext(GlobalUIContext);
    const stateStoreContext = useContext(StateStoreContext);
    
    const changeProfileAvatar = async (formData) => {
        const successCallback = (user) => {
            stateStoreContext.setUser(user);
            globalUIContext.setLoading(false);
        }

        const errorCallback = (error) => {
            console.log(error);
            globalUIContext.setLoading(false);
        }

        globalUIContext.setLoading(true);

        await updateAvatar(formData, errorCallback, successCallback);
    }

    const updateUser = async (details) => {
        const successCallback = (user) => {
            stateStoreContext.setUser(user);
            globalUIContext.setLoading(false);
            toast.success("Your details have been updated.");
        }

        const errorCallback = (error) => {
            console.log(error);
            globalUIContext.setLoading(false);
        }
        
        globalUIContext.setLoading(true);
        await updateUserDetails(details, errorCallback, successCallback);
    }

    const updatePassword = async (details) => {
        const successCallback = (user) => {
            stateStoreContext.setUser(user);
            globalUIContext.setLoading(false);
            toast.success("Your password has been updated.");
        }

        const errorCallback = (error) => {
            console.log(error);
            globalUIContext.setLoading(false);
        }
        
        globalUIContext.setLoading(true);
        await updateUserDetails(details, errorCallback, successCallback);
    }

    return (
        <ProfileContext.Provider value={{
            state: {
                
            },
            changeProfileAvatar,
            updateUser,
            updatePassword
            }}>
            {children}
        </ProfileContext.Provider>
    );
}

const ProfileConsumer = ProfileContext.Consumer;

export { ProfileContext, ProfileProvider, ProfileConsumer }
