import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';

const Sidebar = (props) => {
    const globalUIContext = useContext(GlobalUIContext);
    const stateStoreContext = useContext(StateStoreContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(stateStoreContext.state.user && stateStoreContext.state.user.status > 1 && !stateStoreContext.state.user.initial && stateStoreContext.state.isLoggedIn) {
            // setTimeout(() => {
                setVisible(true);
            // }, 2500);
        } else {
            setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateStoreContext.state.user, stateStoreContext.state.isLoggedIn]);

    return (
        <>
            <div className={`overlay overlay--nav ${globalUIContext.state.mobileNav ? 'is-open' : ''}`} onClick={() => globalUIContext.setMobileNav(!globalUIContext.state.mobileNav)}></div>
            <div className={`sidebar ${visible ? 'is-visible': ''} ${globalUIContext.state.mobileNav ? 'mobile-open': ''}`}>
                <NavLink exact to="/" className="sidebar-item" activeClassName="is-active" onClick={() => globalUIContext.setMobileNav(false)}>
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#home" />   
                        </svg>
                    </div>
                    <span className="sidebar-item__text">Dashboard</span>
                </NavLink>
                <NavLink to="/workout" className="sidebar-item" activeClassName="is-active" onClick={() => globalUIContext.setMobileNav(false)}>
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#dumbbell" />   
                        </svg>
                    </div>
                    <span className="sidebar-item__text">Workout</span>
                </NavLink>
                <NavLink to="/nutrition" className="sidebar-item" activeClassName="is-active" onClick={() => globalUIContext.setMobileNav(false)}>
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition" />   
                        </svg>
                    </div>
                    <span className="sidebar-item__text">Nutrition</span>
                </NavLink>
                <NavLink to="/statistics" className="sidebar-item" activeClassName="is-active" onClick={() => globalUIContext.setMobileNav(false)}>
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#graph" />   
                        </svg>
                    </div>
                    <span className="sidebar-item__text">Statistics</span>
                </NavLink>
                <NavLink to="/progress" className="sidebar-item" activeClassName="is-active" onClick={() => globalUIContext.setMobileNav(false)}>
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#camera" />   
                        </svg>
                    </div>
                    <span className="sidebar-item__text">Progress</span>
                </NavLink>
                <a href="#0" className="sidebar-item sidebar-item--logout" onClick={() => stateStoreContext.logout()}>
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#logout" />   
                        </svg>
                    </div>
                    <span className="sidebar-item__text">Logout</span>
                </a>
            </div>
        </>
    );
}

export default Sidebar;