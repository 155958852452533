// Progress Photo Upload

export const progressUpload = async (data, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/media/progress`, {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            body: data
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                successCallback(res);
            } else if (res.status === 413) {
                errorCallback("filesize", res.message);
            }
        })
        .catch(err => {
            errorCallback("server", err);
        });
}

export const getProgressPictures = async (month, year, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/progress?month=${month}&year=${year}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            successCallback(res);
        })
        .catch(err => {
            errorCallback(err);
        });
}