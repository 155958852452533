import React from 'react';

const ProactiveNutritionConfirmation = ({ open, adjustmentData, closeConfirmation, confirmAdjustments }) => {
    return (
        <>
        <div className={`overlay ${open ? 'is-open' : ''}`} onClick={() => closeConfirmation()}></div>
        <div className={`drawer ${open ? 'is-open' : ''}`}>
            <div className="drawer__header">
                On-Track Adjustment
            </div>
            <div className="drawer__content p-1">
                <p>Description and breakdown of adjustments</p>
            </div>
            <div className="drawer__footer">
                <button className="btn" onClick={() => confirmAdjustments()}>Accept</button>
            </div>
        </div>
        </>
    );
}

export default ProactiveNutritionConfirmation;