import React from 'react';

// Components
import NutritionTarget from '../../Components/NutritionTarget/NutritionTarget';
import ProactiveNutrition from '../../Components/ProactiveNutrition/ProactiveNutrition';

const MyNutrition = (props) => {
    return (
        <div className="layout-grid">
            <NutritionTarget />
            <ProactiveNutrition />
        </div>
    );
}

export default MyNutrition;