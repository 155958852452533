import React from 'react';

const WorkoutEditorSetup = ({ meta, handleChange }) => {
    return (
        <div className="workout-editor-setup">
            <h2>Workout Editor</h2>
            <div className="workout-editor-setup__inner">
                <div className="form-group">
                    <label>Workout Name</label>
                    <input type="text" name="name" value={meta.name} onChange={(e) => handleChange(e)} />
                </div>
                <div className="form-group">
                    <label>Workout Type (Home/Gym/Mixed)</label>
                    <select name="type" value={meta.type} onChange={(e) => handleChange(e)}>
                        <option value="gym">Gym</option>
                        <option value="home">Home</option>
                        <option value="mixed">Mixed</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Level</label>
                    <select name="level" value={meta.level} onChange={(e) => handleChange(e)}>
                        <option value="beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Schedule Type</label>
                    <select name="scheduleType" value={meta.scheduleType} onChange={(e) => handleChange(e)}>
                        <option value="day">Day Based Scheduling</option>
                        <option value="cycle">Cycle Based Scheduling (e.g. Push/Pull/Legs)</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default WorkoutEditorSetup;