import React from 'react';

const NextCheckInCard = ({ checkInToggle }) => {
    return (
        <div className="card next-checkin">
            <div className="next-checkin__icon">
                <div className="sidebar-item__icon">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#calendar" />
                    </svg>
                </div>
            </div>
            <div className="next-checkin__content">
                <p className="next-checkin__subtitle">Your next check-in</p>
                <p className="next-checkin__title">Tomorrow</p>
                {/* <p className="next-checkin__subtitle">{dayLabel}</p>   */}
                <button className="btn btn--white btn--icon" onClick={() => checkInToggle()}>
                    Check-in
                    <span>                                
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-right" />
                        </svg>
                    </span>
                </button>
            </div>
        </div>
    );
}

export default NextCheckInCard;