import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

// Contexts
import { StripeProvider } from 'react-stripe-elements';
import { GlobalUIProvider } from './Contexts/GlobalUIContext/GlobalUIContext';
import { StateStoreProvider } from './Contexts/StateStoreContext/StateStoreContext';
import { LogProvider } from './Contexts/LogContext/LogContext';
import { CheckInProvider } from './Contexts/CheckInContext/CheckInContext';
import { ProfileProvider } from './Contexts/ProfileContext/ProfileContext';
import { SubscriptionProvider } from './Contexts/SubscriptionContext/SubscriptionContext';
import { ProgressProvider } from './Contexts/ProgressContext/ProgressContext';

// Containers
import Initialised from './Containers/Initialised';
import ScrollToTop from './Containers/ScrollToTop/ScrollToTop';
import Main from './Containers/Main/Main';
import Login from './Containers/Login/Login';
import Dashboard from './Containers/Dashboard/Dashboard';
import Welcome from './Containers/Welcome/Welcome';
import Workout from './Containers/Workout/Workout';
import Statistics from './Containers/Statistics/Statistics';
import Profile from './Containers/Profile/Profile';
import WorkoutCreate from './Containers/WorkoutCreate/WorkoutCreate';
import WorkoutEdit from './Containers/WorkoutEdit/WorkoutEdit';
import Progress from './Containers/Progress/Progress';
import Nutrition from './Containers/Nutrition/Nutrition';

// Components
import Loader from './Components/Loader/Loader';
import Header from './Components/Header/Header';
import Sidebar from './Components/Sidebar/Sidebar';
import Log from './Components/Log/Log';

// Utilities
import { ProtectedPage } from './Utils/PageProtection/PageProtection';
import LogBookEntry from './Components/LogBook/LogBookEntry';
import { NutritionProvider } from './Contexts/NutritionContext/NutritionContext';

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <GlobalUIProvider>
          <StateStoreProvider>
            {/* <StripeProvider apiKey="pk_test_wZLYPSrYJlnUlWNAqw1eCc4p">
              <ProfileProvider>
                <CheckInProvider>
                  <NutritionProvider>
                    <SubscriptionProvider>
                      <Header />
                      <Sidebar />
                      <Route path="/" component={Login} exact />
                      <Route path="/welcome" component={Welcome} exact />
                      <Main>
                        <Initialised>
                          <LogProvider>
                            <ProtectedPage path="/dashboard" Component={Dashboard} exact />
                            <Route path="/workout" component={Workout} />
                            <Route path="/log/:workoutid" component={Log} exact />
                            <Route path="/log/entry/:logid" component={LogBookEntry} exact />
                          </LogProvider>
                          <Route path="/statistics" component={Statistics} />
                          <Route path="/profile" component={Profile} exact />
                          <Route path="/workout/create" component={WorkoutCreate} exact />
                          <Route path="/workout/edit/:workoutid" component={WorkoutEdit} exact />
                          <Route path="/nutrition" component={Nutrition} />
                          <ProgressProvider>
                            <Route path="/progress" component={Progress} exact />
                          </ProgressProvider>
                        </Initialised>
                      </Main>
                      <ToastContainer />
                      <Loader />
                    </SubscriptionProvider>
                  </NutritionProvider>
                </CheckInProvider>
              </ProfileProvider>
            </StripeProvider> */}
          </StateStoreProvider>
        </GlobalUIProvider>
      </ScrollToTop>
    </Router>
  );
}

export default App;
