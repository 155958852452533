import React, { useEffect, useState } from 'react';

const ActivityCalendarDay = ({ date, data }) => {
    const [activityDuration, setActivityDuration] = useState(0);

    useEffect(() => {
        let totalTime = 0;

        data.forEach(log => {
            let durationArray = log.duration.split(":"),
                h = durationArray[0],
                m = durationArray[1],
                s = parseInt(durationArray[2]);

            h = parseInt((h * 60) * 60);
            m = parseInt(m * 60);

            totalTime = parseInt(totalTime) + h + m + s;
        });

        setActivityDuration(totalTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="calendar__grid-item">
            <span>{activityDuration > 0 ? '' : date}</span>
            {activityDuration > 0 && activityDuration <= 1800 &&
                <div className="calendar__activity"></div>
            }
            {activityDuration > 1800 && activityDuration <= 3600 &&
                <div className="calendar__activity calendar__activity--med"></div>
            }
            {activityDuration > 3600 &&
                <div className="calendar__activity calendar__activity--lrg"></div>
            }
        </div>
    );
}

export default ActivityCalendarDay;