import React, { useContext } from 'react';

// Contexts
import { WelcomeContext } from '../../Contexts/WelcomeContext/WelcomeContext';

// Components
import WelcomeForm from '../../Components/Forms/WelcomeForm/WelcomeForm';

const WelcomeStep2 = (props) => {
    const welcomeContext = useContext(WelcomeContext);

    const handleFormSubmit = (details) => {
        welcomeContext.setWelcomeData({...welcomeContext.state.welcomeData, ...details});
        welcomeContext.nextStep();
    }

    return (
        <div className="split-panel">
            <div className="split-panel__section">
                <div className="split-panel__inner">
                    <h1>Just a few details</h1>
                    <p>So that we can provide you with a personal and accurate plan, we will need a few details from you.</p>
                </div>
                <div className="split-panel__prev">
                    <button className="icon-btn" onClick={() => welcomeContext.prevStep()}>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-left" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="split-panel__section">
                <div className="split-panel__inner">
                    <WelcomeForm initialValues={welcomeContext.state.welcomeData} handleFormSubmit={(details) => handleFormSubmit(details)} />
                </div>
            </div>
        </div>
    );
}

export default WelcomeStep2;