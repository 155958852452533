import React, { useState, useContext, useEffect } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Utils
import { exerciseDataById } from '../../Utils/Exercise/Exercise';

const ExerciseSelectorBasketItem = ({ exerciseid, remove }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const [exerciseData, setExerciseData] = useState(false);
    
    useEffect(() => {
        let data = exerciseDataById(stateStoreContext.state.exercises, exerciseid);
        setExerciseData(data);
    }, []);

    return (
        <>
        {exerciseData ?
            <div className="exercise-selector__basket-item">
                <div className="thumbnail mr-1">
                    <img src={exerciseData.image} alt={exerciseData.name} />
                </div>
                <span>{exerciseData.name}</span>
                <button className="icon-btn ml-auto" onClick={() => remove(exerciseid)}>
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#plus" />
                    </svg>
                </button>
            </div>
        :
            <></>
        }
        </>
    );
}

export default ExerciseSelectorBasketItem;