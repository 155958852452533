import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Container, Draggable } from 'react-smooth-dnd';

// Context
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';

// Components
import WorkoutEditorSetup from './WorkoutEditorSetup';
import WorkoutEditorToolbar from './WorkoutEditorToolbar';
import WorkoutEditorDay from './WorkoutEditorDay';
import ExerciseSelector from '../ExerciseSelector/ExerciseSelector';

// Utils
import { addNewDay, addNewExercise, addNewSet, addSuperSet, updateSetData, removeDayData, removeExerciseData, removeSetItem } from '../../Utils/WorkoutEditor/WorkoutEditor';
import { saveWorkout } from '../../Utils/Requests/Workout';

const WorkoutEditor = ({ workout = false }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const globalUIContext = useContext(GlobalUIContext);
    const [meta, setMeta] = useState({name: "", public: false, type: "gym", level: "beginner", scheduleType: "day"});
    const [workoutData, setWorkoutData] = useState([]);
    const [exerciseSelector, setExerciseSelector] = useState({open: false, dayid: ""});

    useEffect(() => {
        // Check if editing
        if(workout) {
            const { name, type, level, scheduleType, data } = workout;
            setMeta({
                name,
                public: true,
                type,
                level,
                scheduleType
            });

            setWorkoutData(data);
        }
    }, []);

    const toggleExerciseSelector = (dayid, state, selected = null, superset = false, exerciseid = null) => {
        if(state === "open") {
            setExerciseSelector({open: true, dayid, superset, exerciseid});
        } else {
            if(selected && exerciseSelector.superset) {
                let days = [...workoutData],
                    exercises = [...stateStoreContext.state.exercises],
                    dataObj = addSuperSet(dayid, exerciseid, days, selected, exercises);

                setWorkoutData(dataObj);
                setExerciseSelector({open: false, dayid: "", superset: false, exerciseid: null});
            } else if (selected && !exerciseSelector.superset) {
                let days = [...workoutData],
                    exercises = [...stateStoreContext.state.exercises];

                days.forEach(day => {
                    if(day.id === dayid) {
                        selected.forEach(exerciseid => {
                            let exerciseData = exercises.filter(exercise => exercise._id === exerciseid)[0],
                                exerciseObj = addNewExercise(exerciseid, exerciseData, stateStoreContext.state.profile.units);

                            day.exercises.push(exerciseObj);
                        });
                    }
                });

                setWorkoutData(days);
                setExerciseSelector({open: false, dayid: "", superset: false, exerciseid: null});
            } else {
                setExerciseSelector({open: false, dayid: "", superset: false, exerciseid: null});
            }
        }
    }

    const handleMetaChange = (e) => {
        let metaObj = {...meta};
        metaObj[e.target.name] = e.target.value;
        setMeta(metaObj);
    }

    const handleDayDataChange = (id, e) => {
        let days = [...workoutData];

        days.forEach(day => {
            if(day.id === id) {
                if(e.target.name === "rest") {
                    day[e.target.name] = day.rest ? false : true
                } else {
                    day[e.target.name] = e.target.value;
                }
            }
        });
    
        setWorkoutData(days);
    }

    // Day Related Methods

    const addDay = (rest = false) => {
        let days = [...workoutData],
        newDayObj = addNewDay(days, rest);

        if(days.length === 7 && meta.scheduleType === "day") {
            toast.warn("Maximum of 7 days only on the day based scheduling system.");
        } else {
            days.push(newDayObj);

            setWorkoutData(days);
        }
    }

    const removeDay = (dayid, e) => {
        e.preventDefault();

        let days = [...workoutData],
            dataObj = removeDayData(dayid, days);

        setWorkoutData(dataObj);
    }

    // Exercise Related Methods

    const removeExercise = (dayid, exerciseid, superset = false, parentId) => {
        let days = [...workoutData],
            dataObj = removeExerciseData(dayid, exerciseid, days, superset, parentId);
        
        setWorkoutData(dataObj);
    }

    // Set Related Methods

    const addSet = (dayid, exerciseid, eid, superset) => {
        let days = [...workoutData],
            exerciseData = stateStoreContext.state.exercises.filter(exercise => exercise._id === eid)[0],
            dataObj = addNewSet(dayid, exerciseid, days, exerciseData, stateStoreContext.state.profile.units, superset, stateStoreContext.state.exercises);

        setWorkoutData(dataObj);
    }

    const handleSetDataChange = (dayid, exerciseid, setid, e, supersetitem, parentId) => {
        let days = [...workoutData],
            updatedData = updateSetData(dayid, exerciseid, setid, e, days, supersetitem, parentId);

        setWorkoutData(updatedData);
    }

    const removeSet = (dayid, exerciseid, setid) => {
        let days = [...workoutData],
            dataObj = removeSetItem(dayid, exerciseid, setid, days);

        setWorkoutData(dataObj);
    }

    const addSuperset = (dayid, exerciseid) => {
        toggleExerciseSelector(dayid, "open", null, true, exerciseid);
    }

    // Drag and Drop Related Methods

    const onDrop = (result) => {

        const { removedIndex, addedIndex, payload} = result;

        const days = [...workoutData];

        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = days.splice(removedIndex, 1)[0];
        }
        
        if (addedIndex !== null) {
            days.splice(addedIndex, 0, itemToAdd);
        }

        setWorkoutData(days);

    }

    const onDropExercise = (result, dayid) => {
        console.log(result, dayid);
        const { removedIndex, addedIndex, payload} = result;

        const days = [...workoutData];

        days.forEach(day => {
            if(day.id === dayid) {
                let itemToAdd = payload;

                if (removedIndex !== null) {
                    itemToAdd = day.exercises.splice(removedIndex, 1)[0];
                }
                
                if (addedIndex !== null) {
                    day.exercises.splice(addedIndex, 0, itemToAdd);
                }
            }
        });

        setWorkoutData(days);
    }

    // Save

    const saveWorkoutPlan = async () => {
        // Error Callback
        const errorCallback = (err) => {
            console.log(err);
            globalUIContext.setLoading(false);
            toast.error("There was a problem saving your workout. Please try again.");
        }

        const successCallback = (response) => {
            console.log(response);
            globalUIContext.setLoading(false);
            toast.success("Your workout has been saved.");
        }

        // 1. Build workout object
        const workoutObj = {
            ...meta,
            data: workoutData,
            days: workoutData.length
        }

        // 2. Validate workout object
        // TODO

        // 3. Check whether new workout or update
        // TODO

        // 4. Post data
        globalUIContext.setLoading(true);
        await saveWorkout(workoutObj, errorCallback, successCallback);

    }

    return (
        <>
            <WorkoutEditorSetup meta={meta} handleChange={(e) => handleMetaChange(e)} />
            <WorkoutEditorToolbar addDay={(rest) => addDay(rest)} meta={meta} saveWorkoutPlan={() => saveWorkoutPlan()} />
            <Container onDrop={onDrop}>
                            {workoutData.map((day, index) => 
                                <Draggable key={day.id}>
                                    <WorkoutEditorDay 
                                            key={index} 
                                            index={index + 1} 
                                            data={day} 
                                            meta={meta}
                                            exercises={stateStoreContext.state.exercises} 
                                            handleChange={(e, id) => handleDayDataChange(e, id)} 
                                            removeDay={(dayid, e) => removeDay(dayid, e)}
                                            toggleSelector={() => toggleExerciseSelector(day.id, "open")}
                                            removeExercise={(dayid, exerciseid, superset, parentId) => removeExercise(dayid, exerciseid, superset, parentId)} 
                                            addSet={(dayid, exerciseid, eid, superset) => addSet(dayid, exerciseid, eid, superset)}
                                            removeSet={(dayid, exerciseid, setid) => removeSet(dayid, exerciseid, setid)}
                                            addSuperset={(dayid, exerciseid) => addSuperset(dayid, exerciseid)}
                                            onDropExercise={(result, dayid) => onDropExercise(result, dayid)}
                                            handleSetDataChange={(dayid, exerciseid, setid, e, supersetitem, parentId) => handleSetDataChange(dayid, exerciseid, setid, e, supersetitem, parentId)} /> 
                                </Draggable>
                            )}
            </Container>
            <ExerciseSelector 
                open={exerciseSelector.open !== false}
                superset={exerciseSelector.superset} 
                exerciseid={exerciseSelector.exerciseid} 
                toggleSelector={(dayid, state, selected, superset, exerciseid) => toggleExerciseSelector(dayid, state, selected, superset, exerciseid)} 
                exercises={stateStoreContext.state.exercises} 
                dayid={exerciseSelector.dayid} />
        </>
    );
}

export default WorkoutEditor;