import React, { useContext } from 'react';

// Contexts
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';

const Loader = ({ loading }) => {
    const globalUIContext = useContext(GlobalUIContext);

    return (
        <div className={`loader-container ${globalUIContext.state.loading || loading ? 'is-active': ''}`}>
            <div className="loader"></div>
        </div>
    );
}

export default Loader;