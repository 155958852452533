import React, { createContext, useState, useEffect, useContext } from 'react';

// Contexts
import { GlobalUIContext } from '../GlobalUIContext/GlobalUIContext';

// Utilities
import { getCookie } from '../../Utils/Cookie/Cookie';
import { getUserDetails, getProfileDetails } from '../../Utils/Requests/Account';
import { getExerciseData } from '../../Utils/Requests/Exercise';
import { getPlan } from '../../Utils/Requests/Plan';
import { getLogs } from '../../Utils/Requests/Log';
import { toast } from 'react-toastify';
import { logUserOut, authCheck } from '../../Utils/Requests/Authentication';
import LoginContainer from '../../Containers/StatusContainers/LoginContainer';
import MainContainer from '../../Containers/StatusContainers/MainContainer';
import WelcomeContainer from '../../Containers/Welcome/WelcomeContainer';
import ConfirmContainer from '../../Containers/StatusContainers/ConfirmContainer';
import BlockedContainer from '../../Containers/StatusContainers/BlockedContainer';
import CancelledContainer from '../../Containers/StatusContainers/CancelledContainer';

const StateStoreContext = createContext();

const StateStoreProvider = ({ children }) => {
    const globalUIContext = useContext(GlobalUIContext);

    const [initialised, setInitialised] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [profile, setProfile] = useState({});
    const [plan, setPlan] = useState({});
    const [logs, setLogs] = useState([]);
    const [exercises, setExercises] = useState([]);

    useEffect(() => {
        globalUIContext.setLoading(true);
        
        const initialLoad = async () => {
            // 1. - make post request to check if a cookie exists
            let authenticationCheck = await authCheck();

            if(authenticationCheck) {
                // User is logged in
                setInitialAppData();
            } else {
                // User is not logged in
                setUser({status: -1});
                setInitialised(true);
                globalUIContext.setLoading(false);
            }
        }
        
        initialLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchExerciseData = async () => {
            getExerciseData((err) => alert(err), (data) => handleSuccessExerciseData(data));
        }
    
        const handleSuccessExerciseData = (data) => {
            setExercises(data);
        }

        if(isLoggedIn && exercises.length < 1) {
            fetchExerciseData();
        }
    }, [isLoggedIn, setExercises, exercises]);

    const setInitialAppData = async () => {
        //1. Get account object
        let userObj = await getUserDetails();

        setUser(userObj);
        //2. Get profile object
        let profileObj = await getProfileDetails();
        setProfile(profileObj);

        //3. Get plan object
        let planObj = {};
        if(!userObj.initial) {
            planObj = await getPlan();
        }
        setPlan(planObj);

        //4. Get users logs
        let workoutLogs = [];
        if(!userObj.initial) {
            workoutLogs = await getLogs(1);
        }
        setLogs(workoutLogs);

        setIsLoggedIn(true);
        setInitialised(true);
        globalUIContext.setLoading(false);
    }

    const paginatedLogs = async (page) => {
        globalUIContext.setLoading(true);
        let workoutLogs = await getLogs(page);

        setLogs(workoutLogs);
        globalUIContext.setLoading(false);
    }
    
    const logout = async () => {

        const errorCallback = () => {
            globalUIContext.setLoading(false);
            toast.error("There was an issue logging you out. Please try again.");
        }

        const successCallback = () => {
            window.location.href = `/`;
            setInitialised(false);
        }

        globalUIContext.setLoading(true);
        await logUserOut(errorCallback, successCallback);
    }

    return (
        <StateStoreContext.Provider value={{
            state: {
                initialised,
                isLoggedIn,
                user,
                profile,
                plan,
                logs,
                exercises
            },
            setInitialised,
            setIsLoggedIn,
            setUser,
            setProfile,
            setPlan,
            setLogs,
            setInitialAppData,
            paginatedLogs,
            logout
        }}>
            <>
                {initialised &&
                    <>
                        {user.status === -2 &&
                            <BlockedContainer />
                        }
                        {user.status === -1 &&
                            <LoginContainer />
                        }
                        {user.status === 0 &&
                            <ConfirmContainer />
                        }
                        {user.status === 1 &&
                            <WelcomeContainer />
                        }
                        {(user.status === 2 || user.status === 3) && 
                            <MainContainer />
                        }
                        {user.status === 4 &&
                            <CancelledContainer />
                        }
                    </>
                }
            </>
        </StateStoreContext.Provider>
    );
}

const StateStoreConsumer = StateStoreContext.Consumer;

export { StateStoreContext, StateStoreProvider, StateStoreConsumer }