import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import WorkoutOverviewExercise from './WorkoutOverviewExercise';
import WorkoutOverviewSet from './WorkoutOverviewSet';

// Utils
import { titleCase } from '../../Utils/Manipulation/Manipulation';

const WorkoutOverviewDay = ({day, logbook = false, resetLog}) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className="workout-day">
            <div className="workout-day__header">
                <h3>{day.specificDay ? <>{titleCase(day.specificDay)}: </> : ""}
                {day.name}
                </h3>
            </div>
            <div className={`workout-day__main ${expanded ? 'is-expanded': ''} ${logbook ? 'is-expanded' : ''}`}>
                {day.exercises.map((exercise, index) =>
                    <React.Fragment key={index}> 
                        <WorkoutOverviewExercise key={index} exercise={exercise} />  
                        {logbook && exercise.sets.map((set, index) => 
                            <WorkoutOverviewSet set={set} index={index} key={index} />
                        )}
                        {exercise.notes &&
                                <div className="workout-day__row workout-day__note">
                                    <svg>
                                        <use xlinkHref="/images/icon-library.svg#notes" />
                                    </svg>
                                    {exercise.notes}
                                </div>}
                    </React.Fragment>
                )}
                {!logbook &&
                    <div className="workout-day__expand">
                        <button className="icon-btn icon-btn--white" onClick={() => setExpanded(expanded ? false : true)}>
                            {expanded && <svg>
                                <use xlinkHref="/images/icon-library.svg#chevron-up" />
                            </svg>}
                            {!expanded && <svg>
                                <use xlinkHref="/images/icon-library.svg#chevron-down" />
                            </svg>}
                        </button>
                    </div>
                }
            </div>
            {!logbook &&
            <div className="workout-day__row">
                <Link to={`/log/${day.id}`} className="btn btn--blue btn--icon" onClick={() => resetLog()}>
                    Start Workout
                    <span>                                
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#fire" />
                        </svg>
                    </span>    
                </Link>
            </div>
            }
        </div>
    );
}

export default WorkoutOverviewDay;