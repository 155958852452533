import React from 'react';

// Radio input
const RadioButton = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    className,
    ...props
  }) => {
    return (
      <div className={`radio-style ${id === value ? 'is-selected' : ''}`}>
        <input
          name={name}
          id={id}
          type="radio"
          value={id}
          checked={id === value}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
        <label htmlFor={id}>
          {label}
        </label>
      </div>
    );
  };

  export default RadioButton;