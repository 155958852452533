import React, { useState } from 'react';
import ClickToClose from '../ClickToClose/ClickToClose';

const FilterDropdown = ({ children }) => {
    const [open, setOpen] = useState(false);
    
    return (
        <ClickToClose close={() => setOpen(false)}>
            <div className="filter-dropdown">
                <button className="filter-dropdown__trigger" aria-expanded={open} onClick={() => setOpen(!open)}>
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#filter" />
                    </svg>
                </button>
                <div className="filter-dropdown__content">
                    <div className="filter-dropdown__title">Filter by:</div>
                    {children}
                </div>
            </div>
        </ClickToClose>
    );
}

export default FilterDropdown;