import React, { useState, useContext } from 'react';

// Contexts
import { CheckInContext } from '../../Contexts/CheckInContext/CheckInContext';

// Components
import CheckIn from '../../Components/CheckIn/CheckIn';
import NextCheckInCard from '../../Components/NextCheckInCard/NextCheckInCard';
import AreaChartCard from '../../Components/AreaChartCard/AreaChartCard';
import StatisticsCard from '../../Components/StatisticsCard/StatisticsCard';

const BodyStats = (props) => {
    const checkInContext = useContext(CheckInContext);
    const [checkIn, setCheckIn] = useState(false);
    const [view, setView] = useState("weight");

    const areaChartSelection = (months) => {
        checkInContext.getCheckInList(months);
    }

    return (
        <div className="layout-grid">
            <NextCheckInCard checkInToggle={() => setCheckIn(!checkIn)} />
            <AreaChartCard icon="graph"
                            title={view}
                            data={checkInContext.state.checkIns}
                            dataKey={view}
                            toolTipValue={view === "weight" ? "kg" : "cm"}
                            selectionChange={(months) => areaChartSelection(months)} />
            <StatisticsCard data={checkInContext.state.completeStats}
                            view={view}
                            onStatSelect={(selection) => setView(selection)} />
            <CheckIn open={checkIn} checkInToggle={() => setCheckIn(!checkIn)} />
        </div>
    );
}

export default BodyStats;