import React, { useContext, useState } from 'react';

// Contexts
import { WelcomeContext } from '../../Contexts/WelcomeContext/WelcomeContext';

const WelcomeStep3 = (props) => {
    const welcomeContext = useContext(WelcomeContext);
    const [goal, setGoal] = useState(welcomeContext.state.welcomeData.goal);
    const [error, setError] = useState("");

    const handleContinue = () => {
        if(goal === "") {
            setError("Please select a goal from above.");
        } else {
            setError("");
            welcomeContext.setWelcomeData({...welcomeContext.state.welcomeData, goal: goal});
            welcomeContext.nextStep();
        }
    }

    return (
        <div className="split-panel">
            <div className="split-panel__section">
                <div className="split-panel__inner">
                    <h1>Your Goal</h1>
                    <p>Please select your current fitness goal.</p>
                </div>
                <div className="split-panel__prev">
                    <button className="icon-btn" onClick={() => welcomeContext.prevStep()}>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-left" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="split-panel__section hidden-mobile">
                <div className="split-panel__inner">
                    <div className="grid grid--2-col mb-1">
                        <div className={`radio-style ${goal === "weightloss" ? 'is-selected' : ''}`}>
                            <input type="radio" name="type" value="weightloss" checked={goal === "weightloss"} onChange={() => { setError(""); setGoal("weightloss")}} />
                            <label>Lose Weight</label>
                        </div>
                        <div className={`radio-style ${goal === "build" ? 'is-selected' : ''}`}>
                            <input type="radio" name="type" value="build" checked={goal === "build"} onChange={() => { setError(""); setGoal("build")}} />
                            <label>Build Muscle</label>
                        </div>
                        <div className={`radio-style ${goal === "maintain" ? 'is-selected' : ''}`}>
                            <input type="radio" name="type" value="maintain" checked={goal === "maintain"} onChange={() => { setError(""); setGoal("maintain")}} />
                            <label>Build Strength</label>
                        </div>
                        <div className={`radio-style ${goal === "lean" ? 'is-selected' : ''}`}>
                            <input type="radio" name="type" value="lean" checked={goal === "lean"} onChange={() => { setError(""); setGoal("lean")}} />
                            <label>Get Lean / Toned</label>
                        </div>
                    </div>
                    <div className={`info-reveal ${goal === "" ? '' : 'is-open'}`}>
                        {goal === "weightloss" &&
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>}
                        {goal === "build" &&
                            <p>Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>}
                        {goal === "maintain" &&
                            <p>Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>}
                        {goal === "lean" &&
                            <p>Sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>}
                    </div>
                    {error !== "" && <p>{error}</p>}
                    <button className="btn btn--icon mt-1" onClick={() => handleContinue()}>
                        Continue
                        <span>                                
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#chevron-right" />
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default WelcomeStep3;