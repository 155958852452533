import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import LogBookSummary from './LogBookSummary';

const LogBookEntry = ({ match, history }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const [log, setLog] = useState({});

    useEffect(() => {
        let log = stateStoreContext.state.logs.filter(item => item._id === match.params.logid)[0];
        setLog(log);
    }, []);
    
    return (
        <>
        {Object.entries(log).length === 0 && log.constructor === Object ?
            <p>No log found.</p>
        :
        <>
            <div className="log-book-item">
                <div className="log-book-item__date">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#calendar" />
                    </svg>
                    <p><b>{moment(log.date).format("Do MMM YYYY")}</b></p>
                </div>
                <div className="log-book-item__name">
                    <p>{log.data.name}</p>
                    <div className="log-book-item__stats">
                        <span>
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#stopwatch" />
                            </svg>    
                            {log.duration}
                        </span>
                    </div>
                </div>
                <div className="log-book-item__action">
                    <button className="icon-btn" onClick={() => history.goBack()}>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-left" />
                        </svg>
                    </button>
                </div>
            </div>
            <LogBookSummary workoutData={log.data} />
        </>
        }
        </>
    );
}

export default LogBookEntry;