import React from 'react';
import { Formik, Form, Field } from 'formik';

// Utilities
import { detailsSchema } from '../../../Utils/Validation/FormValidation';

const DetailsForm = ({ details, handleFormSubmit, error }) => {
    const { firstname, lastname, email, phone, dob } = details;
    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    phone: phone,
                    dob: dob
                }}
                validationSchema={detailsSchema}
                onSubmit={(details) => {
                    handleFormSubmit(details);
                }}>
                {({ errors, touched }) => (
                    <>
                        <Form>
                            <div className="form-group">
                                <label htmlFor="firstname">First Name</label>
                                <Field type="text" name="firstname" id="firstname" />
                                {errors.firstname && touched.firstname ? <div className="error">{errors.firstname}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">Last Name</label>
                                <Field type="text" name="lastname" id="lastname" />
                                {errors.lastname && touched.lastname ? <div className="error">{errors.lastname}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <Field type="text" name="email" id="email" />
                                {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone Number</label>
                                <Field type="text" name="phone" id="phone" />
                                {errors.phone && touched.phone ? <div className="error">{errors.phone}</div> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <Field type="text" name="dob" id="dob" />
                                {errors.dob && touched.dob ? <div className="error">{errors.dob}</div> : null}  
                            </div>
                            <button className="btn btn--white btn--icon" type="submit">
                                Update
                                <span>
                                    <svg>
                                        <use xlinkHref="/images/icon-library.svg#chevron-right" />   
                                    </svg> 
                                </span>    
                            </button>
                            {error !== "" && <p>{error}</p>}
                        </Form>
                    </>
                )}
                </Formik>
        </React.Fragment>
    );
}

export default DetailsForm;