import React, { useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

const ProactiveNutritionDrawer = ({ open, closeDrawer, calculateAdjustments }) => {
    const stateStoreContext = useContext(StateStoreContext);
    const [date, setDate] = useState(moment(new Date(), "dd/mm/yyyy").toDate());
    const [kcalEstimate, setKcalEstimate] = useState(0);

    const handleSubmit = () => {
        let data = {
            date: moment(date).hour(0).minute(0).seconds(0).millisecond(0).toDate(),
            surplus: kcalEstimate - stateStoreContext.state.plan.nutrition.target,
            protein: stateStoreContext.state.plan.nutrition.protein,
            target: stateStoreContext.state.plan.nutrition.target
        }

        calculateAdjustments(data, date);
    }

    return (
        <>
        <div className={`overlay ${open ? 'is-open' : ''}`} onClick={() => closeDrawer()}></div>
        <div className={`drawer ${open ? 'is-open' : ''}`}>
            <div className="drawer__header">
                <h3>Stay on track</h3>
            </div>
            <div className="drawer__content p-1">
                <p>To stay on track towards your fitness goals we just need a few pieces of information:</p>
                <label>Date:</label>
                <DatePicker
                    selected={moment(date).toDate()}
                    onChange={date => setDate(date)}
                    dateFormat="dd/mm/yyyy"
                    withPortal
                    customInput={<button className="btn">{moment(date).format("DD/MM/YYYY")}</button>} />
                <label>How many calories do you think you will consume/consumed on this day?</label>
                <input type="number" min="0" value={kcalEstimate} onChange={(e) => setKcalEstimate(e.target.value)} />
            </div>
            <div className="drawer__footer">
                <button className="btn" onClick={() => handleSubmit()}>Keep me on track</button>
            </div>
        </div>
        </>
    );
}

export default ProactiveNutritionDrawer;