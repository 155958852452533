import React, { useContext } from 'react';

// Contexts
import { WelcomeContext } from '../../Contexts/WelcomeContext/WelcomeContext';

const WelcomeStep1 = ({ stateStore }) => {
    const welcomeContext = useContext(WelcomeContext);

    return (
        <div className="split-panel">
            <div className="split-panel__section">
                <div className="split-panel__inner">
                    <h1>Welcome, {stateStore ? stateStore.user.firstname : ''}</h1>
                    <p>To provide you with a tailored experience and set you on your way to acheiving your health and fitness goals we just need a few basic details about you.</p>
                    <button className="btn btn--white btn--icon" onClick={() => welcomeContext.nextStep()}>
                        Continue
                        <span>                                
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#chevron-right" />
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <div className="split-panel__section hidden-mobile">
                <div className="split-panel__inner">
                    <img className="w100-hauto" src="/images/workout-illustration.svg" alt="Workout illustration" />
                </div>
            </div>
        </div>
    );
}

export default WelcomeStep1;