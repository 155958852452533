import React from 'react';

// Components
import ActivityCalendar from '../ActivityCalendar/ActivityCalendar';
import WorkoutStatsCard from '../WorkoutStatsCard/WorkoutStatsCard';
import WorkoutStatsChart from '../WorkoutStatsChart/WorkoutStatsChart';

const WorkoutStats = (props) => {
    return (
        <div className="layout-grid">
            <WorkoutStatsCard />
            <ActivityCalendar />
            <WorkoutStatsChart />
        </div>
    );
}

export default WorkoutStats;