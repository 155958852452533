import React from 'react';
import { Formik, Form, Field } from 'formik';

// Components
import RadioButtonGroup from '../../../Components/InputFields/RadioButton/RadioButtonGroup';
import RadioButton from '../../../Components/InputFields/RadioButton/RadioButton';

// Utilities
import { welcomeSchema } from '../../../Utils/Validation/FormValidation';

const WelcomeForm = ({ initialValues, handleFormSubmit }) => {
    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    gender: initialValues.gender,
                    units: initialValues.units,
                    height: initialValues.height,
                    weight: initialValues.weight,
                    activity: initialValues.activity
                }}
                validationSchema={welcomeSchema}
                onSubmit={(details) => {
                    handleFormSubmit(details);
                }}>
                {({ errors, touched, values }) => (
                    <>
                        <Form>
                            <fieldset>
                                <label htmlFor="gender">Gender</label>
                                <RadioButtonGroup
                                    id="gender"
                                    label="One of these please"
                                    value={values.gender}
                                    error={errors.gender}
                                    touched={touched.gender}
                                >
                                    <Field
                                    component={RadioButton}
                                    name="gender"
                                    id="male"
                                    label="Male"
                                    />
                                    <Field
                                    component={RadioButton}
                                    name="gender"
                                    id="female"
                                    label="Female"
                                    />
                                    {errors.gender && touched.gender ? <div className="error">{errors.gender}</div> : null}
                                </RadioButtonGroup>
                                <label htmlFor="units">Preferred Units</label>
                                <Field component="select" name="units" id="units">
                                    <option value="metric">Metric (kg, cm)</option>
                                    <option value="imperial">Imperial (lbs, inches)</option>
                                </Field>
                                {errors.units && touched.units ? <div className="error">{errors.units}</div> : null}
                                <div className="grid grid--2-col">
                                    <div>
                                        <label htmlFor="height" >Height {values.units === "metric" ? '(cm)' : '(inches)'}</label>
                                        <Field type="number" name="height" id="height" />
                                        {errors.height && touched.height ? <div className="error">{errors.height}</div> : null}
                                    </div>
                                    <div>
                                        <label htmlFor="weight">Bodyweight {values.units === "metric" ? '(kg)' : '(lbs)'}</label>
                                        <Field type="number" name="weight" id="weight" />
                                        {errors.weight && touched.weight ? <div className="error">{errors.weight}</div> : null}
                                    </div>
                                </div>
                                <label htmlFor="activity">Activity Level</label>
                                <Field component="select" name="activity" id="activity">
                                    <option value="Sedentary">Sedentary</option>
                                    <option value="Light">Light</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="Very">Very</option>
                                    <option value="Extreme">Extreme</option>
                                </Field>
                                {errors.activity && touched.activity ? <div className="error">{errors.activity}</div> : null}
                                <button className="btn btn--icon" type="submit">
                                    Continue
                                    <span>                                
                                        <svg>
                                            <use xlinkHref="/images/icon-library.svg#chevron-right" />
                                        </svg>
                                    </span>
                                </button>
                            </fieldset>
                        </Form>
                    </>
                )}
                </Formik>
        </React.Fragment>
    );
}

export default WelcomeForm;