import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';

// Contexts
import { CheckInContext } from '../../Contexts/CheckInContext/CheckInContext';
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';

// Components
import CheckInModal from './CheckInModal';

const CheckIn = ({ open, checkInToggle }) => {
    const checkInContext = useContext(CheckInContext);
    const globalUIContext = useContext(GlobalUIContext);

    const defaultCheckIn = {
        weight: 0,
        neck: 0,
        arm: 0,
        chest: 0,
        waist: 0,
        glutes: 0,
        thigh: 0,
        calf: 0
    };
    const [checkIn, setCheckIn] = useState(defaultCheckIn);

    const updateCheckInDate = (e) => {
        setCheckIn({...checkIn, [e.target.name]: e.target.value});
    }

    const handleClose = () => {
        setCheckIn(defaultCheckIn);
        checkInToggle();
    }

    const handleSubmitCheckIn = async () => {
        const callBack = (type, data) => {
            if(type === "success") {
                handleClose();
                // if(data.adjustment) {
                //     setAdjustment({ open: true, data: data.adjustmentData });
                // } else {
                //     toast.success("Your check-in has been saved.");
                // }
                globalUIContext.setLoading(false);
            }
        }

        globalUIContext.setLoading(true);
        await checkInContext.saveNewCheckIn(checkIn, callBack);
    }

    return (
        <>
            <div className={`overlay ${open ? 'is-open' : ''}`} onClick={() => handleClose()}></div>
            <div className={`drawer ${open ? 'is-open' : ''}`}>
                <div className="drawer__header">
                    <h3>Check-in</h3>
                </div>
                <div className="drawer__content">
                    <div className="p-1">
                        <label>Bodyweight (kg)</label>
                        <input type="number" name="weight" value={checkIn.weight} onChange={(e) => updateCheckInDate(e)} />
                        <label>Neck (cm)</label>
                        <input type="number" name="neck" value={checkIn.neck} onChange={(e) => updateCheckInDate(e)} />
                        <label>Arm (cm)</label>
                        <input type="number" name="arm" value={checkIn.arm} onChange={(e) => updateCheckInDate(e)} />
                        <label>Chest (cm)</label>
                        <input type="number" name="chest" value={checkIn.chest} onChange={(e) => updateCheckInDate(e)} />
                        <label>Waist (cm)</label>
                        <input type="number" name="waist" value={checkIn.waist} onChange={(e) => updateCheckInDate(e)} />
                        <label>Glutes (cm)</label>
                        <input type="number" name="glutes" value={checkIn.glutes} onChange={(e) => updateCheckInDate(e)} />
                        <label>Thigh (cm)</label>
                        <input type="number" name="thigh" value={checkIn.thigh} onChange={(e) => updateCheckInDate(e)} />
                        <label>Calf (cm)</label>
                        <input type="number" name="calf" value={checkIn.calf} onChange={(e) => updateCheckInDate(e)} />
                    </div>
                </div>
                <div className="drawer__footer">
                    <button className="btn" onClick={() => handleSubmitCheckIn()}>Submit</button>
                </div>
            </div>
            <CheckInModal open={checkInContext.state.adjustment.open} data={checkInContext.state.adjustment.data} />
        </>
    );
}

export default CheckIn;