import React, { useContext } from 'react';

// Contexts
import { WelcomeContext } from '../../Contexts/WelcomeContext/WelcomeContext';

// Components
import WelcomeForm2 from '../../Components/Forms/WelcomeForm/WelcomeForm2';

const WelcomeStep4 = (props) => {
    const welcomeContext = useContext(WelcomeContext);

    const handleFormSubmit = (details) => {
        welcomeContext.setWelcomeData({...welcomeContext.state.welcomeData, ...details});
        welcomeContext.setGenerate(true);
    }

    return (
        <div className={`split-panel ${welcomeContext.state.generated ? 'transition-out' : ''}`}>
            <div className="split-panel__section">
                <div className="split-panel__inner">
                    <h1>Nearly there...</h1>
                    <p>We have just a few more questions before we can get started.</p>
                </div>
                <div className="split-panel__prev">
                    <button className="icon-btn" onClick={() => welcomeContext.prevStep()}>
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#chevron-left" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="split-panel__section">
                <div className="split-panel__inner">
                    <WelcomeForm2 initialValues={welcomeContext.state.welcomeData} handleFormSubmit={(details) => handleFormSubmit(details)} />
                </div>
            </div>
        </div>
    );
}

export default WelcomeStep4;