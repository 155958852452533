import React from 'react';

// Components
import ProfileCard from '../../Components/ProfileCard/ProfileCard';
import UserDetailsCard from '../../Components/UserDetailsCard/UserDetailsCard';
import ChangePasswordCard from '../../Components/ChangePasswordCard/ChangePasswordCard';
import PaymentHistory from '../../Components/PaymentHistory/PaymentHistory';
import PaymentMethodCard from '../../Components/PaymentMethodCard/PaymentMethodCard';
import SubscriptionActionCard from '../../Components/SubscriptionActionCard/SubscriptionActionCard';

const Profile = (props) => {
    return (
        <div className="layout-grid">
            <ProfileCard hideDropdown={true} />
            <UserDetailsCard />
            <ChangePasswordCard />
            <PaymentHistory />
            <PaymentMethodCard />
            <SubscriptionActionCard />
        </div>
    );
}

export default Profile;