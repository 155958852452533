import React from 'react';

// Input Feedback
const InputFeedback = ({ error }) =>
    error ? (
    <div className="test">{error}</div>
    ) : null;
  
// Radio group
const RadioButtonGroup = ({
    value,
    error,
    touched,
    id,
    label,
    className,
    children
  }) => {
  
    return (
      <React.Fragment>
          <div className="grid grid--2-col mb-1 mt-1">
            {children}
            {touched &&
            <InputFeedback error={error} />
            }
          </div>
      </React.Fragment>
    );
  };

  export default RadioButtonGroup;