import React from 'react';
import { Formik, Form, Field } from 'formik';

// Utilities
import { loginSchema } from '../../../Utils/Validation/FormValidation';

const LoginForm = ({ title, handleFormSubmit, error }) => {
    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={loginSchema}
                onSubmit={(credentials) => {
                    handleFormSubmit(credentials);
                }}>
                {({ errors, touched }) => (
                    <>
                        <h1>{title}</h1>
                        <Form>
                            <label htmlFor="email">Email address</label>
                            <Field type="email" name="email" id="email" />
                            {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
                            <label htmlFor="password">Password</label>
                            <Field type="password" name="password" id="password" />
                            {errors.password && touched.password ? <div className="error">{errors.password}</div> : null}
                            <button className="btn btn--white btn--icon" type="submit">
                                Login
                                <span>
                                    <svg>
                                        <use xlinkHref="/images/icon-library.svg#chevron-right" />   
                                    </svg> 
                                </span>    
                            </button>
                            {error !== "" && <p>{error}</p>}
                        </Form>
                    </>
                )}
                </Formik>
        </React.Fragment>
    );
}

export default LoginForm;