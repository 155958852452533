import React, { useContext } from 'react';

// Contexts
import { LogContext } from '../../Contexts/LogContext/LogContext';

const LogSet = ({ set, target, index, exerciseid, logGhost }) => {
    const logContext = useContext(LogContext);

    return (
        <>
        {set.type === "weight" ?
            <div className="log-set">
                <div className="log-set__number">
                    Set {index}
                </div>
                <div className="log-set__target">
                    <small>Target</small>
                    <div className="f-container">
                        <div className="f-item">
                            <p>{target.repRange ? `${target.from} - ${target.to}` : target.reps} <span>Reps</span></p>
                        </div>
                        <div className="f-item">
                            {target.weight !== "" && <p>{target.weight} <span>{target.units}</span></p>}
                        </div>
                    </div>
                </div>
                <div className="log-set__achieved">
                    <small>Achieved</small>
                    <div className="f-container">
                        <div className="f-item log-input">
                            <input type="number" name="reps" placeholder={logGhost.reps} value={set.reps} onChange={(e) => logContext.updateSet(exerciseid, set.id, e)} />
                            <p>Reps</p>
                        </div>
                        <div className="f-item log-input">
                            <input type="number" name="weight" placeholder={logGhost.weight} value={set.weight} onChange={(e) => logContext.updateSet(exerciseid, set.id, e)} />
                            <p>kg</p>
                        </div>
                    </div>
                </div>
            </div>
            :
            <p>Cardio</p>
        }
        </>
    );
}

export default LogSet;