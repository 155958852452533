import React from 'react';

const CheckInModal = ({ open, data }) => {
    return (
        <div className={`overlay ${open ? 'is-open' : ''}`}>
            <div className="exercise-modal-container">
                <div className={`general-modal ${open ? 'is-open' : ''}`}>
                    <h2>Adjustment</h2>
                    <p>Increase calories by {data.value}.</p>
                </div>
            </div>
        </div>
    );
}

export default CheckInModal;