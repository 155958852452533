import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

// Utils
import { calcOTAdjustments, confirmOTAdjustments, fetchUpcomingOTAdjustments } from '../../Utils/Requests/OnTrack';

const NutritionContext = createContext();

const NutritionProvider = ({ children }) => {
    const [upcomingAdjustments, setUpcomingAdjustments] = useState([]);
    const [todayAdjustment, setTodayAdjustment] = useState({});

    const duplicateAdjustmentCheck = (adjustmentList) => {
        let uniqueDates = adjustmentList.map(adjustment => moment(adjustment.date).format("DD/MM/YYYY"));

        uniqueDates = [...new Set(uniqueDates)];

        let combinedAdjustmentList = [];

        uniqueDates.forEach(date => {
            let items = adjustmentList.filter(adjustment => moment(adjustment.date).format("DD/MM/YYYY") === date);

            if(items.length > 1) {
                // Multiple to combine
                let newObj = {
                    date: items[0].date,
                    adjustment: {

                    }
                }

                let originalKcals = items[0].target + (items[0].adjustment.adjustment * -1),
                    protein = items[0].adjustment.protein,
                    totalDeduction = 0;

                items.forEach(adjustment => {
                    totalDeduction = totalDeduction + adjustment.adjustment.adjustment;
                });
                

                let remainingKcals = (originalKcals + totalDeduction) - (protein * 4);

                let carbohydrates = Math.round(((remainingKcals*0.6) / 4).toFixed(0));
                
                let fats = Math.round(((remainingKcals*0.4) / 9).toFixed(0));
                console.log(items[0].target, items[0].adjustment.adjustment, originalKcals, totalDeduction);
                newObj.adjustment.target = originalKcals + totalDeduction;
                newObj.adjustment.adjustment = totalDeduction;
                newObj.adjustment.protein = protein;
                newObj.adjustment.carbohydrates = carbohydrates;
                newObj.adjustment.fats = fats;

                console.log(newObj);

                combinedAdjustmentList.push(newObj);
            } else {
                // Single
                combinedAdjustmentList.push(items[0]);
            }
        });

        return combinedAdjustmentList;
    }

    const getOnTrackAdjustments = async () => {
        const successCallback = (res) => {
            let adjustmentList = [],
                today = moment().hour(0).minute(0).second(0),
                todaysAdjustments = [];

            res.data.adjustments.forEach(adjustment => {
                adjustment.adjustments.forEach(item => {
                    item.adjustment.target = item.target;
                    
                    if(moment(item.date).isAfter(today)) {
                        adjustmentList.push(item);
                    }

                    if(moment(item.date).hour(0).minute(0).seconds(0).diff(moment().hour(0).minute(0).seconds(0), "hours") < 23 && moment(item.date).hour(0).minute(0).seconds(0).diff(moment().hour(0).minute(0).seconds(0), "hours") >= 0) {
                        todaysAdjustments.push(item);
                    }
                });
            });

            console.log(todaysAdjustments);

            adjustmentList = duplicateAdjustmentCheck(adjustmentList);

            setUpcomingAdjustments(adjustmentList);

            if(todaysAdjustments.length > 0) {
                if(todaysAdjustments.length === 1) {
                    setTodayAdjustment(todaysAdjustments[0]);
                } else {
                    let newObj = {
                        date: todaysAdjustments[0].date,
                        adjustment: {

                        }
                    }

                    let originalKcals = todaysAdjustments[0].adjustment.target + (todaysAdjustments[0].adjustment.adjustment * -1),
                        protein = todaysAdjustments[0].adjustment.protein,
                        totalDeduction = 0;

                    todaysAdjustments.forEach(adjustment => {
                        totalDeduction = totalDeduction + adjustment.adjustment.adjustment;
                    });
                    

                    let remainingKcals = (originalKcals + totalDeduction) - (protein * 4);

                    let carbohydrates = Math.round(((remainingKcals*0.6) / 4).toFixed(0));
                    
                    let fats = Math.round(((remainingKcals*0.4) / 9).toFixed(0));

                    newObj.adjustment.target = originalKcals + totalDeduction;
                    newObj.adjustment.adjustment = totalDeduction;
                    newObj.adjustment.protein = protein;
                    newObj.adjustment.carbohydrates = carbohydrates;
                    newObj.adjustment.fats = fats;

                    console.log(newObj);

                    setTodayAdjustment(newObj);
                }
            }
        }

        const errorCallback = (err) => {
            toast.error("There was a problem getting  your upcoming On Track adjustments. Please refresh the page and try again.");
        }

        await fetchUpcomingOTAdjustments(errorCallback, successCallback);
    }

    return (
        <NutritionContext.Provider value={{
            state: {
                upcomingAdjustments,
                todayAdjustment
            },
            getOnTrackAdjustments
            }}>
            {children}
        </NutritionContext.Provider>
    );
}

const NutritionConsumer = NutritionContext.Consumer;

export { NutritionContext, NutritionProvider, NutritionConsumer }
