import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

// Contexts
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';

// Components
import WorkoutOverview from '../../Components/WorkoutOverview/WorkoutOverview';
import WorkoutSubNav from './WorkoutSubNav';
import LogBook from '../../Components/LogBook/LogBook';
import VideoLibrary from '../../Components/VideoLibrary/VideoLibrary';

const Workout = (props) => {
    const globalUIContext = useContext(GlobalUIContext);

    return (
        <>
            <WorkoutSubNav selected={globalUIContext.state.workoutTab} onSelect={(selection) => globalUIContext.setWorkoutTab(selection)} />
            <Route path="/workout" component={WorkoutOverview} exact />
            <Route path="/workout/logbook" component={LogBook} exact />
            <Route path="/workout/library" component={VideoLibrary} exact />
        </>
    );
}

export default Workout;