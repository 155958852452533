import React, { useContext } from 'react';

// Contexts
import { ProfileContext } from '../../Contexts/ProfileContext/ProfileContext';

// Components
import ChangePasswordForm from '../../Components/Forms/ChangePasswordForm/ChangePasswordForm';

const ChangePasswordCard = (props) => {
    const profileContext = useContext(ProfileContext);

    return (
        <div className="card reset-password-card">
                <div className="reset-password-card__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition"></use>
                        </svg>
                    </div>
                    <div className="reset-password-card__title">Change My Password</div>
                </div>
                <div className="reset-password-card__content">
                    <ChangePasswordForm handleFormSubmit={(details) => profileContext.updatePassword(details)} />
                </div>
            </div>
    );
}

export default ChangePasswordCard;