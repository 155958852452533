export const calcOTAdjustments = async (data, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/plan/ontrack`, {
        method: 'POST',
        credentials: 'include',
         mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            successCallback(res);
        } else {
            errorCallback(res);
        }
    })
    .catch(err => {
        errorCallback(err);
    });
}

export const confirmOTAdjustments = async (data, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/plan/ontrack/confirm`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            successCallback(res);
        } else {
            errorCallback(res);
        }
    })
    .catch(err => {
        errorCallback(err);
    });
}

export const fetchUpcomingOTAdjustments = async (errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/plan/ontrack`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
    })
    .then(res => res.json())
    .then(res => {
        if(res.status === 200) {
            successCallback(res);
        } else {
            errorCallback(res);
        }
    })
    .catch(err => {
        errorCallback(err);
    });
}