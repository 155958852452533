import React, { useContext } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { NutritionContext } from '../../Contexts/NutritionContext/NutritionContext';

const NutritionTarget = (props) => {
    const stateStoreContext = useContext(StateStoreContext);
    const nutritionContext = useContext(NutritionContext);

    if(Object.entries(nutritionContext.state.todayAdjustment).length !== 0 && nutritionContext.state.todayAdjustment.constructor === Object) {
        return (
            <div className="card nutrition-target">
                <div className="nutrition-target__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition" />
                        </svg>
                    </div>
                    <div className="nutrition-target__title">Todays Nutrition</div>    
                </div> 
                <div className="nutrition-target__content">
                    <div className="nutrition-target__stats">
                        <div className="nutrition-stat">
                            <span className="nutrition-stat__primary">{nutritionContext.state.todayAdjustment.adjustment.target}</span><span className="nutrition-stat__unit">kcals</span>
                        </div>
                        <div className="nutrition-stat">
                            <span className="nutrition-stat__primary">{nutritionContext.state.todayAdjustment.adjustment.protein}g </span><span className="nutrition-stat__unit">Protein</span>
                        </div>
                        <div className="nutrition-stat">
                            <span className="nutrition-stat__primary">{nutritionContext.state.todayAdjustment.adjustment.carbohydrates}g </span><span className="nutrition-stat__unit">Carbs</span>
                        </div>
                        <div className="nutrition-stat">
                            <span className="nutrition-stat__primary">{nutritionContext.state.todayAdjustment.adjustment.fats}g </span><span className="nutrition-stat__unit">Fat</span>
                        </div>
                    </div>
                </div>           
            </div>
        );
    } else {
        return (
            <>
            {Object.entries(stateStoreContext.state.plan).length !== 0 && stateStoreContext.state.plan.constructor === Object ?
                <div className="card nutrition-target">
                    <div className="nutrition-target__icon">
                        <div className="sidebar-item__icon">
                            <svg>
                                <use xlinkHref="/images/icon-library.svg#nutrition" />
                            </svg>
                        </div>
                        <div className="nutrition-target__title">Todays Nutrition</div>    
                    </div> 
                    <div className="nutrition-target__content">
                        <div className="nutrition-target__stats">
                            <div className="nutrition-stat">
                                <span className="nutrition-stat__primary">{stateStoreContext.state.plan.nutrition.target}</span><span className="nutrition-stat__unit">kcals</span>
                            </div>
                            <div className="nutrition-stat">
                                <span className="nutrition-stat__primary">{stateStoreContext.state.plan.nutrition.protein}g </span><span className="nutrition-stat__unit">Protein</span>
                            </div>
                            <div className="nutrition-stat">
                                <span className="nutrition-stat__primary">{stateStoreContext.state.plan.nutrition.carbohydrates}g </span><span className="nutrition-stat__unit">Carbs</span>
                            </div>
                            <div className="nutrition-stat">
                                <span className="nutrition-stat__primary">{stateStoreContext.state.plan.nutrition.fats}g </span><span className="nutrition-stat__unit">Fat</span>
                            </div>
                        </div>
                    </div>           
                </div>
            :
                <div>No data found</div>
            }
            </>
        );
    }
}

export default NutritionTarget;