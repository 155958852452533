export const authenticate = async (credentials, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/account/authenticate`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...credentials})
        })
        .then(res => res.json())
        .then(res => {
            let { status, message } = res;

            if(status === 200) {
                successCallback(res);
            } else if (status === 500) {
                errorCallback("server", message);
            } else {
                errorCallback("authorization", message);
            }
        })
        .catch(err => {
            errorCallback("server", err);
        });
}

export const logUserOut = async (errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/account/logout`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({})
        })
        .then(res => res.json())
        .then(res => {
            let { status, message } = res;

            if(status === 200) {
                successCallback(res);
            } else {
                errorCallback(message);
            }
        })
        .catch(err => {
            errorCallback(err);
        });
}

export const authCheck = async () => {
    let result = await fetch(`${window.API_ENDPOINT}/account/authenticate/check`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({})
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                return {
                    auth: true,
                    status: res.data.status
                };
            } else {
                return false;
            }
        })
        .catch(err => {
            console.lof(err);
            return false;
        });

    return result;
}