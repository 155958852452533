import React, { useContext } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

const NutritionCard = (props) => {
    const stateStoreContext = useContext(StateStoreContext);

    return (
        <>
        {Object.entries(stateStoreContext.state.plan).length !== 0 && stateStoreContext.state.plan.constructor === Object ?
            <div className="card nutrition-card">
                <div className="nutrition-card__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition" />
                        </svg>
                    </div>
                    <div className="nutrition-card__title">My Nutrition</div>    
                </div> 
                <div className="nutrition-card__content">
                    <div className="calories-stat">
                        <div className="calorie-stat__inner">
                            <p><span className="calories-stat__primary">{stateStoreContext.state.plan.nutrition.target}</span>kcals</p>
                            <p className="calories-stat__label">Per Day</p>
                        </div>
                    </div>
                    <div className="nutrition-card__stats">
                        <div className="nutrition-stat">
                            <span className="nutrition-stat__primary">{stateStoreContext.state.plan.nutrition.protein}g </span><span className="nutrition-stat__unit">Protein</span>
                        </div>
                        <div className="nutrition-stat">
                            <span className="nutrition-stat__primary">{stateStoreContext.state.plan.nutrition.carbohydrates}g </span><span className="nutrition-stat__unit">Carbs</span>
                        </div>
                        <div className="nutrition-stat">
                            <span className="nutrition-stat__primary">{stateStoreContext.state.plan.nutrition.fats}g </span><span className="nutrition-stat__unit">Fat</span>
                        </div>
                    </div>
                </div>           
            </div>
        :
            <div>No data found</div>
        }
        </>
    );
}

export default NutritionCard;