import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import CheckIn from '../CheckIn/CheckIn';

const QuickAction = (props) => {
    const [checkIn, setCheckIn] = useState(false);

    return (
        <>
        <div className="card quick-action">
            <Link to={`/workout/edit/5db21cbd646a450017f995ab`} className="sidebar-item sidebar-item--alt">
                <div className="sidebar-item__icon">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#camera" />
                    </svg>
                </div>
                <span className="sidebar-item__text">Action</span>
            </Link>
            <button className="sidebar-item sidebar-item--alt" onClick={() => setCheckIn(!checkIn)}>
                <div className="sidebar-item__icon">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#calendar" />
                    </svg>
                </div>
                <span className="sidebar-item__text">Check-In</span>
            </button>
            <Link to="/workout/create" className="sidebar-item sidebar-item--alt">
                <div className="sidebar-item__icon">
                    <svg>
                        <use xlinkHref="/images/icon-library.svg#calendar" />
                    </svg>
                </div>
                <span className="sidebar-item__text">Action</span>
            </Link>
        </div>
        <CheckIn open={checkIn} checkInToggle={() => setCheckIn(false)} />
        </>
    );
}

export default QuickAction;