import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';
import { GlobalUIContext } from '../../Contexts/GlobalUIContext/GlobalUIContext';

// Components
import Avatar from '../Avatar/Avatar';
import IconDropdown from '../IconDropdown/IconDropdown';

const Header = (props) => {
    const globalUIContext = useContext(GlobalUIContext);
    const stateStoreContext = useContext(StateStoreContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(stateStoreContext.state.user && stateStoreContext.state.user.status > 1 && !stateStoreContext.state.user.initial && stateStoreContext.state.isLoggedIn) {
            // setTimeout(() => {
                setVisible(true);
            // }, 2500);
        } else {
            setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateStoreContext.state.user, stateStoreContext.state.isLoggedIn]);

    return (
        <div className={`main-header ${visible ? 'is-visible': ''}`}>
            <div className="main-header__rc">
                <svg>
                    <use xlinkHref="/images/icon-library.svg#rounded-corner-left" />
                </svg>
            </div>
            <div className="main-header__rc main-header__rc--rhs">
                <svg>
                    <use xlinkHref="/images/icon-library.svg#rounded-corner-left" />
                </svg>
            </div>
            <Link to="/" className="header-logo beta">
                <img src="/images/logo-full.svg" alt="PTSPHERE Logo" />
            </Link>
            <div className="main-header__rhs">
                <IconDropdown icon="chevron-down" size="small" location="right">
                    <button onClick={() => stateStoreContext.logout()}>Logout</button>
                </IconDropdown>
                <Avatar user={stateStoreContext.state.user} />
            </div>
            <div className="main-header__mobile-actions">
                <button className="nav-trigger" onClick={() => globalUIContext.setMobileNav(!globalUIContext.state.mobileNav)}>
                    <svg className={`${globalUIContext.state.mobileNav ? 'visually-hidden': ''}`}>
                        <use xlinkHref="/images/icon-library.svg#menu" />
                    </svg>
                    <svg className={`${globalUIContext.state.mobileNav ? '': 'visually-hidden'}`}>
                        <use xlinkHref="/images/icon-library.svg#close-nav" />
                    </svg>
                    <span className="visually-hidden">Menu</span>
                </button>
            </div>
        </div>
    );
}

export default Header;