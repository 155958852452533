import React from 'react';
import { NavLink } from 'react-router-dom';

const NutritionSubNav = (props) => {
    return (
        <div className="sub-nav">
            <NavLink to="/nutrition" className="pill-btn" activeClassName="is-active" exact>My Nutrition</NavLink>
            <NavLink to="/nutrition/recipes" className="pill-btn" activeClassName="is-active" exact>Recipes</NavLink>
        </div>
    );
}

export default NutritionSubNav;