import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

// Contexts
import { StateStoreContext } from '../Contexts/StateStoreContext/StateStoreContext';

// Components
import Loader from '../Components/Loader/Loader';

const Initialised = ({ children, ...rest }) => {
    const stateStoreContext = useContext(StateStoreContext);

    const safePaths = ['/', '/welcome'];

    if(safePaths.includes(window.location.pathname)) {
        console.log("Safe path");
        return (
            <>
                {children}
            </>
        );
    } else {
        console.log("Not safe path");
        return (
            <>
                {stateStoreContext.state.initialised ?
                    <>
                        {stateStoreContext.state.isLoggedIn ?
                            <>
                                {children}
                            </>
                        :
                            <Route {...rest} component={props => 
                                <Redirect to={{
                                    pathname: "/",
                                    state: { from: props.location }
                                }} />
                            } />
                        }
                        
                    </>
                :
                    <Loader loading={true} />
                }
            </>
        );
    }
}

export default Initialised;