import React, { useEffect, useContext, useState } from 'react';

// Contexts
import { LogContext } from '../../Contexts/LogContext/LogContext';

const LogNote = ({ exerciseid, notes }) => {
    const logContext = useContext(LogContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [exerciseid]);

    return (
        <>
            <div className={`card note-box ${open ? 'is-open' : ''}`}>
                <button className="note-box__header" aria-expanded={open} onClick={() => setOpen(open ? false : true)}>
                    <p className="note-box__title">Add Notes</p>
                    <div className="note-box__indicator">
                        {open ? '-' : '+'}
                    </div>
                </button>
                <div className="note-box__main">
                    <textarea value={notes} onChange={(e) => logContext.updateNotes(exerciseid, {notes: e.target.value})}></textarea>
                </div>
            </div>
        </>
    );
}

export default LogNote;