// Local Utility Functions

const generateId = (type) => {
    let id;

    switch(type) {
        case "day":
            id = "d-" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
            break;
        case "exercise":
            id = "e-" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
            break;
        case "set":
            id = "s-" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
            break;
        default:
            id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
            break;
    }

    return id;
}

const generateDragId = () => {
    return Math.floor(Math.random() * 20);
}

const generateSetObj = (type, units) => {
    let setObj,
        id = generateId("set");

    switch(type) {
        case "resistance":
            setObj = {
                id,
                type: "weight",
                reps: 10,
                weight: 10,
                units: units === "metric" ? "kg" : "lbs",
                repRange: false,
                from: 10,
                to: 12
            };
            break;
        case "cardiovascular":
            setObj = {
                id,
                type: "steady state",
                duration: 600,
                pace: 4.0,
                on: 30,
                off: 10,
                calories: 0
            };
            break;
        default: 
            break;
    }

    return setObj;
}

// Global Utility Functions

export const addNewDay = (days, rest) => {
    let id = generateId("day"),
        dayObj = {
                    id,
                    name: "",
                    specificDay: null,
                    order: days.length + 1,
                    rest: rest,
                    exercises: []
                };

    return dayObj;
}

export const addNewExercise = (exerciseId, exerciseData, units) => {
    let id = generateId("exercise"),
        exerciseObj = {
            id,
            exerciseId,
            sets: [],
            superset: false,
            dragId: generateDragId(),
            additionalExercises: []
        },
        setObj = generateSetObj(exerciseData.type, units);

        exerciseObj.sets.push(setObj);

        return exerciseObj;
}

export const addNewSet = (dayid, exerciseid, days, exerciseData, units, superset, exercises) => {
    days.forEach(day => {
        if(day.id === dayid) {
            day.exercises.forEach(exercise => {
                if(exercise.id === exerciseid) {
                    let set;
                    if(exercise.sets.length > 0) {
                        set = {...exercise.sets.slice(-1).pop()};
                        set.id = generateId("set");
                    } else {
                        set = generateSetObj(exerciseData.type, units)
                    }

                    exercise.sets.push(set);

                    if(superset) {
                        exercise.additionalExercises.forEach(additional => {
                            let exerciseData = exercises.filter(exercise => exercise._id === additional.exerciseid)[0];

                            if(additional.sets.length > 0) {
                                set = {...additional.sets.slice(-1).pop()};
                                set.id = generateId("set");
                            } else {
                                set = generateSetObj(exerciseData.type, units);
                            }

                            additional.sets.push(set);
                        });
                    }
                }
            });
        }
    });

    return days;
}

export const addSuperSet = (dayid, exerciseid, days, selected, exercises, units) => {
    days.forEach(day => {
        if(day.id === dayid) {
            day.exercises.forEach(exercise => {
                if(exercise.id === exerciseid) {
                    let setCount = exercise.sets.length;

                    exercise.superset = true;

                    selected.forEach(item => {
                        let exerciseData = exercises.filter(exercise => exercise._id === item)[0],
                            id = generateId("exercise"),
                            exerciseObj = {
                                id,
                                exerciseId: exerciseData._id,
                                sets: [],
                                order: 1
                            };

                        for(let i = 0; i < setCount; i++) {
                            let setObj = generateSetObj(exerciseData.type, units);

                            exerciseObj.sets.push(setObj);
                        }
                            
                        exercise.additionalExercises.push(exerciseObj);
                    });
                }
            });
        }
    });

    return days;
}

export const updateSetData = (dayid, exerciseid, setid, e, days, superset, parentId) => {
    days.forEach(day => {
        if(day.id === dayid) {
            day.exercises.forEach(exercise => {
                if(superset) {
                    if(exercise.id === parentId) {
                        exercise.additionalExercises.forEach(additional => {
                            if(additional.id === exerciseid) {
                                additional.sets.forEach(set => {
                                    if(set.id === setid) {
                                        set[e.target.name] = e.target.value;
                                    }
                                });
                            }
                        });
                    }
                } else {
                    if(exercise.id === exerciseid) {
                        exercise.sets.forEach(set => {
                            if(set.id === setid) {
                                set[e.target.name] = e.target.value
                            }
                        });
                    }
                }
            });
        }
    });

    return days;
}

export const removeDayData = (dayid, days) => {
    days = days.filter(day => day.id !== dayid);

    return days;
}

export const removeExerciseData = (dayid, exerciseid, days, superset, parentId) => {
    days.forEach(day => {
        if(day.id === dayid) {
            if(superset) {
                day.exercises.forEach(exercise => {
                    if(exercise.id === parentId) {
                        exercise.additionalExercises = exercise.additionalExercises.filter(item => item.id !== exerciseid);
                    }

                    if(exercise.additionalExercises.length === 0) {
                        exercise.superset = false;
                    }
                });

            } else {
                day.exercises = day.exercises.filter(exercise => exercise.id !== exerciseid);
            }
        }
    });

    return days;
}

export const removeSetItem = (dayid, exerciseid, setid, days) => {
    days.forEach(day => {
        if(day.id === dayid) {
            day.exercises.forEach(exercise => {
                if(exercise.id === exerciseid) {
                    let index;
                    for(let i = 0; i < exercise.sets.length; i++) {
                        if(exercise.sets[i].id === setid) {
                            index = i;
                        }
                    }

                    exercise.sets.splice(index, 1);

                    if(exercise.superset) {
                        exercise.additionalExercises.forEach(additional => {
                            additional.sets.splice(index, 1);
                        });
                    }
                }
            });
        }
    });

    return days;
}