import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Containers
import MyNutrition from '../MyNutrition/MyNutrition';

// Components
import Loader from '../../Components/Loader/Loader';
import NutritionSubNav from './NutritionSubNav';

const Nutrition = (props) => {
    const stateStoreContext = useContext(StateStoreContext);
    
    return (
        <>
        {Object.entries(stateStoreContext.state.plan).length === 0 && stateStoreContext.state.plan.constructor === Object ?
            <Loader loading={true} />
            :
            <>
                <NutritionSubNav />
                <Route path="/nutrition" component={MyNutrition} exact />
            </>
        }
        </>
    );
}

export default Nutrition;