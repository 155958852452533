import React, { useState, useContext } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

// Contexts
import { SubscriptionContext } from '../../../Contexts/SubscriptionContext/SubscriptionContext';

const PaymentMethodFormInput = (props) => {
    const subscriptionContext = useContext(SubscriptionContext);
    const [error, setError] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();

        if(props.stripe) {
            props.stripe
                .createToken()
                .then((payload) => {
                    if(payload.error) {
                        setError(payload.error.message);
                    } else {
                        setError("");
                        updatePaymentMethod(payload.token);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            alert("Stripe.js has not loaded yet.");
        }
    }

    const updatePaymentMethod = (token) => {
        // Update customer with new source
        subscriptionContext.updatePaymentMethod(token);
    }

    return (
        <React.Fragment>
            <CardElement hidePostalCode={true} />
            {error !== "" && <p className="error">{error}</p>}
            <button className="btn mt-1" onClick={(e) => onSubmit(e)}>Submit</button>
        </React.Fragment>
    );
}

export default injectStripe(PaymentMethodFormInput);
