import React, { useContext, useState, useEffect, Fragment } from 'react';

// Contexts
import { LogContext } from '../../Contexts/LogContext/LogContext';

const LogSuperSet = ({ set, supersetData, target, index, exerciseid, logGhost, logGhostSuperset, additionalExercises }) => {
    const logContext = useContext(LogContext);
    const [setData, setSetData] = useState([]);
    const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    useEffect(() => {
        let data = [];

        additionalExercises.forEach(exercise => {
            let obj = exercise.sets[index - 1];
            obj.parentExercise = false;
            obj.supersetId = exercise.id;

            data.push(obj);
        });

        setSetData(data);

    }, []);

    return (
        <>
        {set.type === "weight" ?
            <div className="log-set">
                <div className="log-set__number">
                    Set {index}{alphabet[0]}
                </div>
                <div className="log-set__target">
                    <small>Target</small>
                    <div className="f-container">
                        <div className="f-item">
                            <p>{target.repRange ? `${target.from} - ${target.to}` : target.reps} <span>Reps</span></p>
                        </div>
                        <div className="f-item">
                            {target.weight !== "" && <p>{target.weight} <span>{target.units}</span></p>}
                        </div>
                    </div>
                </div>
                <div className="log-set__achieved">
                    <small>Achieved</small>
                    <div className="f-container">
                        <div className="f-item log-input">
                            <input type="number" name="reps" placeholder={logGhost.reps} value={set.reps} onChange={(e) => logContext.updateSet(exerciseid, set.id, e)} />
                            <p>Reps</p>
                        </div>
                        <div className="f-item log-input">
                            <input type="number" name="weight" placeholder={logGhost.weight} value={set.weight} onChange={(e) => logContext.updateSet(exerciseid, set.id, e)} />
                            <p>kg</p>
                        </div>
                    </div>
                </div>
                {setData.map((superset, i) => 
                    <Fragment key={i}>
                    <div className="log-set__number">
                        Set {index}{alphabet[i + 1]}
                    </div>
                    <div className="log-set__target">
                        <small>Target</small>
                        <div className="f-container">
                            <div className="f-item">
                                <p>{superset.repRange ? `${superset.from} - ${superset.to}` : superset.reps} <span>Reps</span></p>
                            </div>
                            <div className="f-item">
                                {superset.weight !== "" && <p>{superset.weight} <span>{superset.units}</span></p>}
                            </div>
                        </div>
                    </div>
                    <div className="log-set__achieved">
                        <small>Achieved</small>
                        <div className="f-container">
                            <div className="f-item log-input">
                                <input type="number" name="reps" placeholder={logGhostSuperset[i].sets[index - 1].reps} value={supersetData[i].sets[index - 1].reps} onChange={(e) => logContext.updateSuperSet(exerciseid, supersetData[i].id, supersetData[i].sets[index - 1].id, e)} />
                                <p>Reps</p>
                            </div>
                            <div className="f-item log-input">
                                <input type="number" name="weight" placeholder={logGhostSuperset[i].sets[index - 1].weight} value={supersetData[i].sets[index - 1].weight} onChange={(e) => logContext.updateSuperSet(exerciseid, supersetData[i].id, supersetData[i].sets[index - 1].id, e)} />
                                <p>kg</p>
                            </div>
                        </div>
                    </div>
                    </Fragment>
                )}
            </div>
            :
            <p>Cardio</p>
        }
        </>
    );
}

export default LogSuperSet;