import React, { useContext } from 'react';

// Components
import WelcomeStep1 from './WelcomeStep1';
import WelcomeStep2 from './WelcomeStep2';
import WelcomeStep3 from './WelcomeStep3';
import WelcomeStep4 from './WelcomeStep4';
import WelcomeComplete from './WelcomeComplete';

// Contexts
import { WelcomeContext } from '../../Contexts/WelcomeContext/WelcomeContext';
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

const WelcomeContainer = (props) => {
    const welcomeContext = useContext(WelcomeContext);
    const stateStoreContext = useContext(StateStoreContext);

    return (
        <React.Fragment>
            {Object.entries(welcomeContext.state.plan).length === 0 && welcomeContext.state.plan.constructor === Object ?
            <>
                {welcomeContext.state.step === 1 &&
                    <WelcomeStep1 stateStore={stateStoreContext.state} />
                }
                {welcomeContext.state.step === 2 &&
                    <WelcomeStep2 />
                }
                {welcomeContext.state.step === 3 &&
                    <WelcomeStep3 />
                }
                {welcomeContext.state.step === 4 &&
                    <WelcomeStep4 />
                }
            </>
            :
            <>
                <WelcomeComplete />
            </>
            }
        </React.Fragment>
    );
}

export default WelcomeContainer;