import React from 'react';
import { StateStoreProvider } from '../../Contexts/StateStoreContext/StateStoreContext';
import Login from '../Login/Login';

// Context Providers

const LoginContainer = (props) => {
    return (
        <Login />
    );
}

export default LoginContainer;