import React from 'react';

// Components
import Notification from '../../Components/Notification/Notification';
import NextWorkoutCard from '../../Components/NextWorkoutCard/NextWorkoutCard';
import ProfileCard from '../../Components/ProfileCard/ProfileCard';
import ActivityCalendar from '../../Components/ActivityCalendar/ActivityCalendar';
import NutritionCard from '../../Components/NutritionCard/NutritionCard';
import QuickAction from '../../Components/QuickAction/QuickAction';
import LatestUpdates from '../../Components/LatestUpdates/LatestUpdates';
import NutritionTarget from '../../Components/NutritionTarget/NutritionTarget';

const Dashboard = (props) => {
    return (
        <>
            {/* <Notification />  */}
            <div className="layout-grid">
                <ProfileCard />
                <QuickAction />
                <ActivityCalendar />
                <NextWorkoutCard />   
                <NutritionCard />
                <NutritionTarget />  
            </div>
            <LatestUpdates />   
        </>
    );
}

export default Dashboard;