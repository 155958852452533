import React from 'react';

// Components
import PaymentMethodForm from '../../Components/Forms/PaymentMethodForm/PaymentMethodForm';

const PaymentMethodCard = (props) => {
    return (
        <div className="card payment-history">
                <div className="payment-history__icon">
                    <div className="sidebar-item__icon">
                        <svg>
                            <use xlinkHref="/images/icon-library.svg#nutrition"></use>
                        </svg>
                    </div>
                    <div className="payment-history__title">Update Payment Method</div>
                </div>
                <div className="payment-history__content">
                    <PaymentMethodForm />
                </div>
            </div>
    );
}

export default PaymentMethodCard;