export const getTransactions = async (errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/account/transactions`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                successCallback(res.data.transactions);
            }
        })
        .catch(err => {
            errorCallback(err);
        });
}

export const updatePaymentCard = async (token, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/account/updatepaymentmethod`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({token: token.id})
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                successCallback(res);
            }
        })
        .catch(err => {
            errorCallback(err);
        });
}

export const cancelSub = async (errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/account/cancel`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                successCallback();
            }
        })
        .catch(err => {
            errorCallback(err);
        });
}

export const resub = async (errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/account/resubscribe`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                successCallback();
            }
        })
        .catch(err => {
            errorCallback(err);
        });
}