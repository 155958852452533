export const generatePlan = async (data, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/plan/generate`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
            let { status, message } = res;

            if(status === 200) {
                successCallback(res);
            } else if (status === 500) {
                errorCallback("server", message);
            } else {
                errorCallback("authorization", message);
            }
        })
        .catch(err => {
            errorCallback("server", err);
        });
}

export const createPlan = async (data, errorCallback, successCallback) => {
    await fetch(`${window.API_ENDPOINT}/plan`, {
        method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
        let { status, message } = res;

        if(status === 200) {
            successCallback(res);
        } else if (status === 500) {
            errorCallback("server", message);
        } else {
            errorCallback("authorization", message);
        }
    })
    .catch(err => {
        errorCallback("server", err);
    });
}

export const getPlan = async (errorCallback, successCallback) => {
    let planObj = await fetch(`${window.API_ENDPOINT}/plan/`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                return res.data.plan;
            }
        })
        .catch(err => {
            console.log(err);
        });

    return planObj;
}