import React, { createContext, useState, useContext, useEffect } from 'react';

// Contexts
import { GlobalUIContext } from '../GlobalUIContext/GlobalUIContext';
import { StateStoreContext } from '../StateStoreContext/StateStoreContext';

// Utils
import { getTransactions, updatePaymentCard, cancelSub, resub } from '../../Utils/Requests/Subscription';
import { toast } from 'react-toastify';

const SubscriptionContext = createContext();

const SubscriptionProvider = ({ children }) => {
    const globalUIContext = useContext(GlobalUIContext);
    const stateStoreContext = useContext(StateStoreContext);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        getTransactionHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTransactionHistory = async () => {
        const successCallback = (transactions) => {
            setTransactions(transactions);
            globalUIContext.setLoading(false);
        }

        const errorCallback = (err) => {
            console.log(err);
            globalUIContext.setLoading(false);
        }

        globalUIContext.setLoading(true);
        getTransactions(errorCallback, successCallback);
    }

    const updatePaymentMethod = async (token) => {
        const successCallback = (data) => {
            globalUIContext.setLoading(false);
            toast.success("Your payment method has been updated.");
        }

        const errorCallback = (err) => {
            globalUIContext.setLoading(false);
            console.log(err);
        }

        globalUIContext.setLoading(true);
        updatePaymentCard(token, errorCallback, successCallback);
    }

    const cancelSubscription = async () => {
        const successCallback = (data) => {
            globalUIContext.setLoading(false);
            toast.warn("Your subscription will be cancelled at the end of the current billing period.");
        }

        const errorCallback = (err) => {
            globalUIContext.setLoading(false);
            console.log(err);
        }

        globalUIContext.setLoading(true);
        cancelSub(errorCallback, successCallback);
    }

    const resubscribe = async () => {
        const successCallback = (data) => {
            globalUIContext.setLoading(false);
            toast.success("Your subscription has been re-enabled.");
            stateStoreContext.setInitialAppData();
        }

        const errorCallback = (err) => {
            globalUIContext.setLoading(false);
            console.log(err);
        }

        globalUIContext.setLoading(true);
        resub(errorCallback, successCallback);
    }
    
    return (
        <SubscriptionContext.Provider value={{
            state: {
                transactions
            },
            getTransactionHistory,
            updatePaymentMethod,
            cancelSubscription,
            resubscribe
            }}>
            {children}
        </SubscriptionContext.Provider>
    );
}

const SubscriptionConsumer = SubscriptionContext.Consumer;

export { SubscriptionContext, SubscriptionProvider, SubscriptionConsumer }
