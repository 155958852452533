import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer
  } from 'recharts';

const WorkoutStatsChartWorkouts = ({ logData, period, startDate, endDate }) => {
    const [data, setData] = useState([]);

    const massageDataWeekly = () => {
        let logs = [...logData];

        let output = [];

        let noOfWeeks = (moment(logs[0].date).diff(moment([logs.length - 1].date), "week") * -1) + 1;

        for(let i = 0; i < noOfWeeks; i++) {
            let betweenStart = moment(logs[0].date).add(i, "weeks"),
                betweenEnd = moment(betweenStart).add(1, "weeks");

            let obj = {
                date: moment(betweenStart).format("DD/MM/YY"),
                Workouts: 0
            }

            logs.forEach(log => {
                if(moment(log.date).isBetween(betweenStart, betweenEnd)) {
                    obj.Workouts = obj.Workouts + 1;
                }
            });

            output.push(obj);
        }

        setData(output);
    }

    const massageDataMonthly = () => {
        let logs = [...logData];

        let output = [];

        let monthDifference = moment(startDate).diff(moment(endDate), "months");

        for(let i = monthDifference; i < 1; i++) {
            let dateMoment = moment(endDate).subtract((i * -1), "months"),
                year = dateMoment.format("YYYY"),
                month = dateMoment.format("MMMM"),
                obj = {
                    date: dateMoment.format("MMMM YYYY"),
                    Workouts: 0
                };

            logs.forEach(log => {
                let logDateMoment = moment(log.date);

                if(logDateMoment.format("YYYY") === year && logDateMoment.format("MMMM") === month) {
                    obj.Workouts = obj.Workouts + 1;
                }
            });
            
            output.push(obj);
        }

        setData(output);
    }

    const tooltipFormat = (value) => {
        // TODO: Display unit depending on users set unit preference
        return value;
    }

    const labelFormat = (value) => {
        if(period === 0) {
            return <b>{moment(value, "DD/MM/YYYY").format("Do MMM YYYY")}</b>;
        } else if (period === 1) {
            return <b>Week of {moment(value, "DD/MM/YYYY").format("Do MMM YYYY")}</b>
        } else {
            return <b>{value}</b>
        }
    }

    useEffect(() => {
        if(logData.length > 0) {
            switch(period) {
                case 1:
                    massageDataWeekly();
                    break;
                case 2:
                    massageDataMonthly();
                    break;
                default:
                    massageDataWeekly();
                    break
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logData, period]);
    
    return (
        <>
        {data.length > 0 &&
        <ResponsiveContainer height={340}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                top: 5, right: 30, left: 20, bottom: 5,
                }}>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip formatter={tooltipFormat}
                                 itemStyle={{ color: '#000' }}
                                 labelFormatter={(value) => labelFormat(value)} />

                <Bar dataKey="Workouts" fill="#4260f4" />
            </BarChart>
        </ResponsiveContainer>
        }
        </>
    );
}

export default WorkoutStatsChartWorkouts;