import React, { useState } from 'react';
import ClickToClose from '../ClickToClose/ClickToClose';

const IconDropdown = (props) => {
    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        let currentStatus = open;
        setOpen(!currentStatus);
    }

    const closeMenu = () => {
        setOpen(false);
    }

    return (
        <ClickToClose close={() => closeMenu()}>
            <div className={`icon-dropdown ${props.size && props.size} ${props.location}`}>
                <button className="icon-dropdown__trigger" aria-expanded={open} onClick={() => toggleMenu()}>
                    <svg>
                        <use xlinkHref={`/images/icon-library.svg#${props.icon}`} />
                    </svg>
                </button>
                <div className="icon-dropdown__menu">
                    {props.children}
                </div>
            </div>
        </ClickToClose>
    );
}

export default IconDropdown;