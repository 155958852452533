import React, { useState, useEffect, useContext } from 'react';

// Contexts
import { StateStoreContext } from '../../Contexts/StateStoreContext/StateStoreContext';

// Components
import VideoLibraryGrid from './VideoLibraryGrid';
import VideoLibraryPagination from './VideoLibraryPagination';
import ExerciseModal from '../ExerciseModal/ExerciseModal';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import FilterDropdownItem from '../FilterDropdown/FilterDropdownItem';

const VideoLibrary = ({ title, videos }) => {
    const stateStoreContext = useContext(StateStoreContext);

    const [videoData, setVideoData] = useState([]);
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [perPage] = useState(12);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [selected, setSelected] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedMuscleGroup, setSelectedMuscleGroup] = useState("");

    useEffect(() => {
        let videos = stateStoreContext.state.exercises;
        // Clone video to use for filtering -> can then reset using the videos props
        // when required
        setVideoData([...videos]);
        // PageCount will need to be updated everytie video state is filtered
        setPageCount(Math.ceil(videos.length / perPage));
        // Muscle Groups
        let muscleGrouping = videos.map(video => video.category);
        muscleGrouping = [...new Set(muscleGrouping.flat())];
        setMuscleGroups(muscleGrouping);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateStoreContext.state.exercises]);

    useEffect(() => {
        let exercises = [...stateStoreContext.state.exercises];
        if(searchQuery !== "") {
            exercises = exercises.filter(exercise => exercise.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        if(selectedMuscleGroup !== "") {
            exercises = exercises.filter(exercise => exercise.category.includes(selectedMuscleGroup));
        }

        setVideoData(exercises);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, selectedMuscleGroup]);

    const handleExerciseSelect = (exerciseObj) => {
        setSelected(exerciseObj);
    }

    const handleClose = () => {
        setSelected({});
    }

    return (
        <>
            <div className="progress-gallery__title">
                <h3>Exercise Library</h3>
                <div className="search-filter">
                    <input type="text" placeholder="Search exercises..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    <FilterDropdown>
                        <FilterDropdownItem label="Muscle Group" options={muscleGroups} onSelect={(e) => setSelectedMuscleGroup(e.target.value)} selected={selectedMuscleGroup} selectText="Select muscle group" />
                    </FilterDropdown>
                </div>
            </div>
            <VideoLibraryGrid videos={videoData.slice((page - 1) * 12, perPage * page)} handleExerciseSelect={(exerciseObj) => handleExerciseSelect(exerciseObj)} />
            <VideoLibraryPagination page={page} pageCount={pageCount} setPage={(page) => setPage(page)} />
            <ExerciseModal open={Object.entries(selected).length !== 0 && selected.constructor === Object} exercise={selected} handleClose={() => handleClose()} />
        </>
    );
}

export default VideoLibrary;