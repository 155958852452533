import React from 'react';

const WorkoutOverviewSet = ({ set, index }) => {
    return (
        <div className="workout-day__row workout-day__row--set">
            <p>Set {index + 1}</p>
            <div className="ml-auto">
                <span className="mr-1">Reps: {set.reps}</span>
                <span>Weight: {set.weight}{set.units}</span>
            </div>
        </div>
    );
}

export default WorkoutOverviewSet;